import { gql } from '@/__generated__';

const SIMULATE_USER = gql(`#graphql
  mutation SimulateUser($userId: Int!) {
    impersonateUser(userId: $userId) {
      id
      email
      name
      lastnames
      token
      organization_id
      username
      phone
      calling_code
      phone_number
      notification {
        id
        email
        sms
        email_status
        email_id
        sms_status
        sms_id
        whatsapp_status
        whatsapp_id
      }
      hasAccess
      notifyMessagesSms
      notifyMessagesWhatsapp
      notifyMessagesEmail
      notifyAlerts
      role {
        id
        name
      }
      organization {
        id
        name
        photo
        address
        website
        phone
        dga_user
        dga_password
        verify_auth_orgs
        access
        created
        has_debt
      }
      organizations {
        id
        name
        photo
        address
        website
        phone
        dga_user
        dga_password
        verify_auth_orgs
        access
        created
        has_debt
        role {
          id
          name
        }
      }
      organizationsArray
      created
      canalName
      canalId
      picture
      access {
        controlCenter
        allDevices
        stations
        users
        virtualStations
        integrations
        dataAnalytics
        dataAdvanceAnalytics
        gates
        systems
        distribution
        dgaReports
        dataExtraction
        deviceConfiguration
        dataTables
        offLogin
        permits
        apiCapta
        distributionMaipo
        metricView
        deviceDiagnostics
        sendCommand
        channelNetwork
        audit
        standardDesign
        announcements
        notifications
        organizations
        billing
        alerts
        scheduledReports
        smaReports
        alertNotification
        viewAllocation
      }
      should_auth
      success
      message
      updatedPassword
      verify_auth
      status
    }
  }
`);

export default SIMULATE_USER;
