import { useFormatters } from '@/hooks/useFormatters';
import Echarts from '@features/echarts';
import { useLingui } from '@lingui/react';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { SmaReports } from 'src/__generated__/graphql';
import { getHourlyChartOption } from '../utils/echartOptions';

const SmaSuccessTable = (props: { reports: SmaReports[]; startTs: number; opacity?: number }) => {
  const { reports, startTs, opacity } = props;
  const { i18n } = useLingui();
  const formatters = useFormatters();
  const option = useMemo(
    () => getHourlyChartOption(reports, startTs, formatters, i18n),
    [formatters, i18n, reports],
  );
  return (
    <Box height={500} overflow="hidden" sx={{ opacity }}>
      {option && <Echarts option={option} />}
    </Box>
  );
};

export { SmaSuccessTable };
