import TextField from '@mui/material/TextField';
import { Control, Controller, FieldValues, Path, UseControllerProps } from 'react-hook-form';

interface FormInputTextProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  type?: React.InputHTMLAttributes<unknown>['type'];
  rules?: UseControllerProps['rules'];
  multiline?: boolean;
  minRows?: number;
}

export const FormInputText = <T extends FieldValues>({
  name,
  control,
  label,
  type = 'text',
  rules = {},
  multiline = false,
  minRows = 0,
}: FormInputTextProps<T>) => {
  return (
    <Controller
      name={name}
      control={control as Control<FieldValues>}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : null}
          error={!!error}
          onChange={(event) =>
            type === 'number' ? onChange(+event.target.value) : onChange(event.target.value)
          }
          value={value}
          fullWidth
          label={label}
          variant="outlined"
          type={type}
          multiline={multiline}
          minRows={minRows}
        />
      )}
      rules={rules}
    />
  );
};
