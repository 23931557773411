import { gql } from '@/__generated__';

const GET_USER_LIST = gql(`#graphql
  query GetUserList($input: UsersInput, $pagination: PaginationInput, $sort: SortInput) {
    usersPagination(input: $input, pagination: $pagination, sort: $sort) {
      items {
        id
        fullName
        email
        name
        lastnames
        username
        phone
        callingCode
        phoneNumber
        picture
        created
        lastAccess
        twoFactorAuth
        verifyAuth
        status
        mainOrganization {
          organization {
            id
            name
            verifyAuthOrgs
          }
          role {
            id
            name
          }
        }
        additionalOrganizations {
          organization {
            id
            name
            verifyAuthOrgs
          }
          role {
            id
            name
          }
        }
      }
      pageInfo {
        count
        limit
        offset
        pageNumber
        totalCount
      }
    }
  }

`);

export default GET_USER_LIST;
