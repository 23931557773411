import { useAuth } from '@/hooks';
import { setModalPreferencesUserId } from '@features/userPreferences/slices/userPreferencesSlice';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { AccountCircle, Logout, Settings } from '@mui/icons-material';
import {
  Avatar,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserProfileModal } from 'src/slices/modals';

const UserMenu = () => {
  const dispatch = useDispatch();
  const { _ } = useLingui();
  const { logout, user } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  if (!user) return null;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = () => {
    dispatch(
      setUserProfileModal({
        open: true,
        userId: user.id,
      }),
    );
  };

  const handleClickPreferences = () => {
    dispatch(setModalPreferencesUserId(user.id));
  };

  const handleClickLogout = () => {
    logout();
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        {user.picture ? (
          <Avatar src={user.picture} alt={_(msg`Imagen de perfil`)} />
        ) : (
          <Avatar>
            {user.name[0]}
            {user.lastnames[0]}
          </Avatar>
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        slotProps={{
          paper: {
            elevation: 0,
            variant: 'outlined',
          },
        }}
      >
        <MenuItem onClick={handleClickProfile}>
          <ListItemAvatar>
            {user.picture ? (
              <Avatar src={user.picture} alt={_(msg`Imagen de perfil`)} />
            ) : (
              <Avatar>
                {user.name[0]}
                {user.lastnames[0]}
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText primary={`${user.name} ${user.lastnames}`} secondary={user.username} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClickProfile}>
          <ListItemIcon>
            <AccountCircle fontSize="small" />
          </ListItemIcon>
          <Trans>Perfil</Trans>
        </MenuItem>
        <MenuItem onClick={handleClickPreferences}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <Trans>Preferencias</Trans>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClickLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Trans>Cerrar sesión</Trans>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
