import { Trans } from '@lingui/macro';
import { Box, Button } from '@mui/material';
import { type FC } from 'react';

const Unauthorized: FC<{ onClose?: () => void }> = ({ onClose }) => {
  return (
    <Box mx="auto" p={3}>
      <img src="https://images.unsplash.com/photo-1622350428710-c5ecfe29265a?q=80&w=300&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
      {onClose && (
        <Box width="100%" textAlign="right">
          <Button onClick={onClose} variant="outlined" color="info">
            <Trans>Cerrar</Trans>
          </Button>
        </Box>
      )}
    </Box>
  );
};
export { Unauthorized };
