import { gql } from '@/__generated__';

const GET_USER_PROFILE = gql(`#graphql
  query GetUserProfile($input: UserInput!) {
    user(input: $input) {
      id
      name
      lastnames
      picture
      username
      phone
      twoFactorAuth
      fullName
      created
      mainOrganization {
        organization {
          id
          name
          photo
        }
        role {
          id
          name
        }
      }
      additionalOrganizations {
        organization {
          id
          name
          photo
        }
        role {
          id
          name
        }
      }
      temporaryUser {
        activationToken
        activationTokenExpiration
        temporaryUserDurationDays
        temporaryUserExpiration
      }
    }
  }
`);

export default GET_USER_PROFILE;
