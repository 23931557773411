import { t } from '@lingui/macro';

export const filters = [
  { id: 'Search', label: t`Buscar dispositivo` },
  { id: 'Organization', label: t`Organizacion` },
  { id: 'Icn', label: t`ICN` },
  { id: 'Type', label: t`Tipo` },
  { id: 'Status', label: t`Estado` },
  { id: 'SupportStatus', label: t`Estado de Soporte` },
  { id: 'Tags', label: t`Tags` },
  { id: 'Dga', label: t`DGA` },
];
