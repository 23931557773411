import { useSuspenseQuery } from '@apollo/client';
import { Trans, msg } from '@lingui/macro';
import { Close, ContentCopy, Delete } from '@mui/icons-material';
import { Box, DialogTitle, Fab, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { Suspense, type FC } from 'react';
import GET_MODAL_TITLE from '../graphql/queries/getModalTitle';
import { useFormatDeviceType } from 'src/hooks/useFormatDeviceType';
import { useDispatch } from 'react-redux';
import { setCloneDeviceModal, setDeleteDeviceModal } from '../slices/deviceConfigurationSlice';
import { useLingui } from '@lingui/react';
import { AuthorizationWrapper, Access } from '@features/authorization';

interface Props {
  deviceId: string;
  onClose: () => void;
}

const Component: FC<Props> = ({ deviceId, onClose }) => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const { data } = useSuspenseQuery(GET_MODAL_TITLE, {
    variables: {
      deviceId,
    },
  });

  const deviceType = useFormatDeviceType(deviceId);

  const actions = [
    {
      id: 1,
      access: Access.CloneDeviceModal,
      label: _(msg`Clonar dispositivo`),
      Icon: ContentCopy,
      color: 'primary',
      action: () => {
        dispatch(
          setCloneDeviceModal({
            open: true,
            deviceId,
          }),
        );
      },
    },
    {
      id: 2,
      access: Access.DeleteDeviceModal,
      label: _(msg`Eliminar dispositivo`),
      Icon: Delete,
      color: 'error',
      action: () => {
        dispatch(
          setDeleteDeviceModal({
            open: true,
            deviceId,
          }),
        );
      },
    },
  ] as const;

  return (
    <DialogTitle
      sx={{
        backgroundColor: '#2a3542',
        display: 'flex',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
          <Close />
        </IconButton>
        <Box pl={2} flexGrow={1}>
          {data.device.profile.name}
          <Typography variant="subtitle2" color="text.secondary">
            ID: <span style={{ color: 'white' }}>{data.device.id}</span>
          </Typography>
        </Box>
      </Box>
      <Box flex={1}></Box>
      <Box display="flex" gap={2}>
        {actions.map(({ id, label, access, Icon, color, action }) => (
          <Suspense
            key={id}
            fallback={
              <Skeleton variant="circular">
                <Fab size="medium" />
              </Skeleton>
            }
          >
            <AuthorizationWrapper access={access} fallback={null} deviceId={deviceId}>
              <Tooltip title={label}>
                <Fab onClick={action} size="medium" color={color}>
                  <Icon />
                </Fab>
              </Tooltip>
            </AuthorizationWrapper>
          </Suspense>
        ))}
      </Box>
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          <Trans>Tipo</Trans>: <span style={{ color: deviceType.color }}>{deviceType.text}</span>
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          <Trans>ORG</Trans>:{' '}
          <span style={{ color: 'white' }}>
            {data.device.profile.organization?.name ?? <Trans>Sin organización</Trans>}
          </span>
        </Typography>
      </Box>
    </DialogTitle>
  );
};

const Fallback: FC<Props> = ({ onClose: handleClose }) => {
  return (
    <DialogTitle
      sx={{
        backgroundColor: '#2a3542',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <Close />
        </IconButton>
        <Box pl={2} flexGrow={1}>
          <Skeleton width={200} />
          <Box display="flex" gap={1}>
            <Typography variant="subtitle2" color="text.secondary">
              ID:
            </Typography>
            <Skeleton>
              <Typography variant="subtitle2">XXXX</Typography>
            </Skeleton>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display="flex" gap={1}>
          <Typography variant="subtitle2" color="text.secondary">
            <Trans>Tipo:</Trans>
          </Typography>
          <Skeleton>
            <Typography variant="subtitle2">Tcore-L</Typography>
          </Skeleton>
        </Box>
        <Box display="flex" gap={1}>
          <Typography variant="subtitle2" color="text.secondary">
            <Trans>ORG:</Trans>
          </Typography>
          <Skeleton>
            <Typography variant="subtitle2">Captahydro</Typography>
          </Skeleton>
        </Box>
      </Box>
    </DialogTitle>
  );
};

const DeviceModalTitle: FC<Props> = (props) => {
  return (
    <Suspense fallback={<Fallback {...props} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default DeviceModalTitle;
