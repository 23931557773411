import { gql } from 'src/__generated__';

const USER_FORM_GET_ROLES = gql(`
  query UserFormGetRoles {
    roles {
      id
      name
    }
  }
`);

export default USER_FORM_GET_ROLES;
