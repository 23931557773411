import { useFormatters } from '@/hooks/useFormatters';
import { useSelector } from '@/store';
import { useSuspenseQuery } from '@apollo/client';
import { Box, Button, Dialog, DialogContent, Skeleton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Suspense, useState, useTransition } from 'react';
import { useDispatch } from 'react-redux';
import { SmaTimeGroups } from 'src/__generated__/graphql';
import ModalActions from 'src/components/modal/ModalActions';
import ModalTitle from 'src/components/modal/ModalTitle';
import { setSmaSuccessTableModal } from 'src/slices/modals';
import GET_SMA_REPORTS from '../graphql/queries/getSmaReports';
import { SmaSuccessTable } from './SmaSuccessTable';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

const currDate = dayjs();
const initialStopDate = currDate.endOf('week').add(1, 'day');
const initialStopTs = initialStopDate.unix();
const initialStartTs = initialStopDate.subtract(6, 'day').startOf('day').unix();

function add1Week(ts: number) {
  return dayjs.unix(ts).add(1, 'week').unix();
}

function substract1Week(ts: number) {
  return dayjs.unix(ts).subtract(1, 'week').unix();
}

const Component = (props: {
  deviceId: string;
  smaUnitId: string;
  smaDeviceId: string;
  smaProcessId: string;
  smaMetricName: string;
  close: () => void;
}) => {
  const { _ } = useLingui();
  const { deviceId, smaUnitId, smaDeviceId, smaProcessId, smaMetricName, close } = props;
  const formatters = useFormatters();
  const [range, setRange] = useState({ startTs: initialStartTs, stopTs: initialStopTs });

  const [isPending, startTransition] = useTransition();

  const { data } = useSuspenseQuery(GET_SMA_REPORTS, {
    variables: {
      inputDevice: {
        id: deviceId,
      },
      inputReports: {
        startTs: range.startTs,
        stopTs: range.stopTs,
        groupByTime: SmaTimeGroups.Hour,
      },
      inputSmaConfig: {
        smaUnitId: smaUnitId,
        smaDeviceId: smaDeviceId,
        smaProcessId: smaProcessId,
        smaMetricName: smaMetricName,
      },
    },
  });

  return (
    <>
      <ModalTitle
        title={_(msg`Gráfico de reportes SMA ${data.device.profile.name}`)}
        onClose={close}
      />
      <DialogContent dividers>
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
          <Typography fontSize={18}>
            <Trans>
              Semana del <b>{formatters.formatDate(dayjs.unix(range.startTs).toDate())}</b> al{' '}
              <b>{formatters.formatDate(dayjs.unix(range.stopTs).toDate())}</b>
            </Trans>
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              onClick={() =>
                startTransition(() =>
                  setRange({
                    startTs: substract1Week(range.startTs),
                    stopTs: substract1Week(range.stopTs),
                  }),
                )
              }
            >
              <Trans>Semana Anterior</Trans>
            </Button>
            <Button
              variant="outlined"
              disabled={range.stopTs > currDate.unix()}
              onClick={() =>
                startTransition(() =>
                  setRange({
                    startTs: add1Week(range.startTs),
                    stopTs: add1Week(range.stopTs),
                  }),
                )
              }
            >
              <Trans>Próxima Semana</Trans>
            </Button>
          </Box>
          <SmaSuccessTable
            reports={data.device.smaConfig[0].reports ?? []}
            startTs={range.startTs}
            opacity={isPending ? 0.5 : 1}
          />
        </Box>
      </DialogContent>
    </>
  );
};

const SkeletonFallBack = (props: { close: () => void }) => {
  const { close } = props;
  const { _ } = useLingui();
  return (
    <>
      <ModalTitle title={_(msg`Gráfico de reportes SMA`)} onClose={close} />
      <DialogContent dividers>
        <Skeleton height={500} variant="rounded" />
      </DialogContent>
    </>
  );
};

const SmaSuccessTableModal = () => {
  const { smaSuccessTableModal: modalState } = useSelector((state) => state.modals_store);
  const { open } = modalState;
  const dispatch = useDispatch();
  const close = () => dispatch(setSmaSuccessTableModal({ open: false }));
  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <Suspense fallback={<SkeletonFallBack close={close} />}>
        {modalState.open && (
          <Component
            close={close}
            deviceId={modalState.deviceId}
            smaUnitId={modalState.smaUnitId}
            smaDeviceId={modalState.smaDeviceId}
            smaProcessId={modalState.smaProcessId}
            smaMetricName={modalState.smaMetricName}
          />
        )}
      </Suspense>
      <ModalActions onClose={close} />
    </Dialog>
  );
};

export { SmaSuccessTableModal };
