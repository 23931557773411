import { ActionType } from '@/__generated__/graphql';
import { useFormatters } from '@/hooks/useFormatters';
import { useSuspenseQuery } from '@apollo/client';
import {
  getActionTypeOptions,
  getColorByPrimitiveActionType,
  getDisplayNameActionType,
  getIconByPrimitiveActionType,
  getPrimitiveActionType,
} from '@features/audit/utils';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineConnectorClasses,
  timelineItemClasses,
} from '@mui/lab';
import { Box, Chip, Link, Tooltip, Typography } from '@mui/material';
import { useState, type FC } from 'react';
import GET_DEVICE_PROFILE_AUDIT_LOGS from '../../graphql/queries/getDeviceProfileAuditLogs';
import SectionTitle from './common/SectionTitle';

interface Props {
  deviceId: string;
}

const availableActions = [
  null,
  ActionType.CreateDevice,
  ActionType.UpdateDeviceStatus,
  ActionType.UpdateDeviceAlerts,
  ActionType.UpdateRatingCurves,
];

const SHOW_FILTERS = false;

const DeviceProfileAudit: FC<Props> = ({ deviceId }) => {
  const { _, i18n } = useLingui();
  const [selectedAction, setSelectedAction] = useState<ActionType | null>(null);
  const actionTypeOptions = getActionTypeOptions(i18n);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <SectionTitle id="audit" title={_(msg`Registro de cambios`)} />
      <Box>
        {SHOW_FILTERS && (
          <Box mb={2} display="flex" gap={1.5}>
            {availableActions.map((action) => {
              const selected = action === selectedAction;
              const label = !action
                ? _(msg`Todos`)
                : actionTypeOptions.find(({ value }) => value === action)?.label ?? action;
              return (
                <Chip
                  size="small"
                  onClick={() => {
                    if (!selected) setSelectedAction(action);
                  }}
                  color="info"
                  variant={selected ? 'filled' : 'outlined'}
                  label={label}
                />
              );
            })}
          </Box>
        )}
        <AuditTimeline deviceId={deviceId} actionType={selectedAction} />
      </Box>
    </Box>
  );
};

interface AuditTimelineProps {
  deviceId: string;
  actionType: ActionType | null;
}

const AuditTimeline: FC<AuditTimelineProps> = ({ deviceId, actionType }) => {
  const { i18n } = useLingui();
  const { formatDateTime } = useFormatters();
  const { data } = useSuspenseQuery(GET_DEVICE_PROFILE_AUDIT_LOGS, {
    variables: {
      input: {
        entityId: `device#${deviceId}`,
        actionType,
      },
      pagination: {
        limit: 5,
      },
    },
  });
  const logs = data.auditLogs.items;

  if (!logs.length)
    return (
      <Typography pt={0.2} fontSize="small" color="text.secondary" fontStyle="italic">
        <Trans>No existen registros</Trans>
      </Typography>
    );

  return (
    <Timeline
      sx={{
        my: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {logs.map((log) => {
        const primitiveActionType = getPrimitiveActionType(log.action.type);

        return (
          <TimelineItem
            key={log.id}
            sx={{
              [`& .${timelineConnectorClasses.root}`]: {
                backgroundColor: 'divider',
              },
            }}
          >
            <TimelineSeparator>
              <TimelineDot color={getColorByPrimitiveActionType(primitiveActionType)}>
                {getIconByPrimitiveActionType(primitiveActionType)}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Tooltip
                title={
                  log.responsible && (
                    <Box>
                      <Typography>
                        {log.responsible ? `ID: ${log.responsible.id}` : 'ID: ??'}
                      </Typography>
                    </Box>
                  )
                }
                placement="top"
              >
                <Typography
                  component="span"
                  sx={
                    log.responsible
                      ? {
                          textDecorationStyle: 'dotted',
                          textDecorationColor: (theme) => theme.palette.text.secondary,
                          textDecoration: 'underline',
                          cursor: 'help',
                        }
                      : {}
                  }
                >
                  {log.responsible ? log.responsible.displayName : <Trans>Un captiano</Trans>}
                </Typography>
              </Tooltip>{' '}
              <Typography color="textSecondary" component="span">
                {getDisplayNameActionType(log.action.type, i18n)}
              </Typography>
              <Typography fontSize="14px" color="text.secondary">
                {formatDateTime(log.timestamp)}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        );
      })}
      {data.auditLogs.pageInfo.lastEvaluatedKey != null && (
        <TimelineItem
          sx={{
            [`& .${timelineConnectorClasses.root}`]: {
              backgroundColor: 'divider',
            },
          }}
        >
          <TimelineSeparator>
            <TimelineDot sx={{ bgcolor: 'divider', mx: 1.3 }}></TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Link
              target="_blank"
              href={(() => {
                const url = new URLSearchParams();
                url.append('entity', `device#${deviceId}`);
                return `/audit?${url.toString()}`;
              })()}
              fontSize="small"
              fontWeight="medium"
            >
              <Trans>Ver más registros en auditoría</Trans>
            </Link>
          </TimelineContent>
        </TimelineItem>
      )}
      {data.auditLogs.pageInfo.lastEvaluatedKey == null && (
        <TimelineItem
          sx={{
            [`& .${timelineConnectorClasses.root}`]: {
              backgroundColor: 'divider',
            },
          }}
        >
          <TimelineSeparator>
            <TimelineDot sx={{ bgcolor: 'divider', mx: 1.3 }}></TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Typography pt={0.2} fontSize="small" color="text.secondary" fontStyle="italic">
              <Trans>No hay más registros para este dispositivo</Trans>
            </Typography>
          </TimelineContent>
        </TimelineItem>
      )}
    </Timeline>
  );
};

export default DeviceProfileAudit;
