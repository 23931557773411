import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import type { Theme } from '@mui/material';
import { Box, Divider, Drawer } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  BrowserNotSupported as BrowserNotSupportedIcon,
  Business as BusinessIcon,
  DeviceHub as DeviceHubIcon,
  Folder as FolderIcon,
  Group as GroupIcon,
  Key as KeyIcon,
  Quiz as QuizIcon,
  Rule as RuleIcon,
  ScheduleSend as ScheduleSendIcon,
  WifiProtectedSetupOutlined as WifiProtectedSetupOutlinedIcon,
  CodeOff as CodeOffIcon,
  Biotech,
  Campaign,
  Notifications,
  DesignServicesOutlined,
  Payment,
  Warning,
  ScatterPlot,
} from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import ControlCenterIcon from 'src/icons/amaruNewIcons/ControlCenterIcon';
import DGAReportIcon from 'src/icons/amaruNewIcons/DGAReportIcon';
import DeviceAnalysisIcon from 'src/icons/amaruNewIcons/DeviceAnalysisIcon';
import DistributionsIcon from 'src/icons/amaruNewIcons/DistributionIcon';
import GatesIcon from 'src/icons/amaruNewIcons/GatesIcon';
import IntegrationsIcon from 'src/icons/amaruNewIcons/IntegrationsIcon';
import useAccess from 'src/hooks/useAccess';
import useAuth from 'src/hooks/useAuth';
import NavSection from 'src/components/NavSection';
import Scrollbar from 'src/components/Scrollbar';
import { LogoCapta } from 'src/components/logo/';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';
import ReportsIcon from 'src/icons/amaruNewIcons/ReportsIcon';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface SectionItem {
  title: string;
  path: string;
  paramsPath?: string | string[];
  icon: JSX.Element;
}

interface Section {
  title: string;
  items: SectionItem[];
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { _ } = useLingui();
  const { onMobileClose, openMobile } = props;
  const { hasAccess, isCaptaUser } = useAccess();

  const location = useLocation();
  const auth = useAuth();
  const [sections, setSections] = useState<Section[]>([]);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    const amaruSection = {
      title: _(msg`Monitoreo`),
      items: [] as SectionItem[],
    };

    const amaruControl = {
      title: _(msg`Control`),
      items: [] as SectionItem[],
    };

    const topologySection = {
      title: _(msg`Análisis`),
      items: [] as SectionItem[],
    };

    const administrationSection = {
      title: _(msg`Configuración`),
      items: [] as SectionItem[],
    };

    const newSection: Section[] = [];

    // Monitoreo
    if (hasAccess('Sec:CentroControl')) {
      amaruSection.items.push({
        title: _(msg`Centro de Control`),
        path: '/',
        icon: <ControlCenterIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:Dispositivos')) {
      amaruSection.items.push({
        title: _(msg`Dispositivos`),
        path: '/devices',
        icon: <ListIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:Integraciones')) {
      amaruSection.items.push({
        title: _(msg`Integraciones`),
        path: '/integrations',
        paramsPath: '/integrations/:deviceId',
        icon: <IntegrationsIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:Soporte')) {
      amaruSection.items.push({
        title: _(msg`Diagnóstico de Dispositivos`),
        path: '/support',
        paramsPath: '/support/:deviceId',
        icon: <RuleIcon fontSize="small" />,
      });
    }

    // Control
    if (hasAccess('Sec:Compuertas')) {
      amaruControl.items.push({
        title: _(msg`Compuertas`),
        path: '/gates',
        paramsPath: '/gates/administration/:gateId',
        icon: <GatesIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:AnalisisEstaciones')) {
      topologySection.items.push({
        title: _(msg`Análisis de Estaciones`),
        path: '/devices-analysis',
        paramsPath: ['/devices-analysis/:deviceId'],
        icon: <DeviceAnalysisIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:AnalisisAvanzado')) {
      topologySection.items.push({
        title: _(msg`Análisis Avanzado de Estaciones`) + ' (Alpha)',
        path: '/advanced-devices-analysis',
        paramsPath: ['/advanced-devices-analysis/:deviceId'],
        icon: <ScatterPlot fontSize="small" />,
      });
    }

    if (hasAccess('Sec:CalculadoraMaipoMenu')) {
      topologySection.items.push({
        title: _(msg`Sistema Reparte Maipo`),
        path: '/distribution-maipo',
        icon: <DistributionsIcon />,
      });
    }

    if (hasAccess('Sec:Reparto')) {
      topologySection.items.push({
        title: _(msg`Reparto`),
        path: '/distribution-initialization',
        paramsPath: '/distribution-history',
        icon: <DistributionsIcon />,
      });
    }

    if (hasAccess('Sec:Reports')) {
      topologySection.items.push({
        title: _(msg`Reportes`),
        path: '/reports',
        icon: <ReportsIcon />,
      });
    }

    if (hasAccess('Sec:ReportesDGA')) {
      topologySection.items.push({
        title: _(msg`Reportes DGA`),
        path: '/dga',
        icon: <DGAReportIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:Sma')) {
      topologySection.items.push({
        title: _(msg`Reportes SMA`),
        path: '/sma',
        icon: <DGAReportIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:MetricsViews') && isCaptaUser) {
      topologySection.items.push({
        title: _(msg`Vista de Métricas`),
        path: '/metrics-views',
        icon: <DeviceAnalysisIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:Anuncios')) {
      administrationSection.items.push({
        title: _(msg`Anuncios`),
        path: '/announcements',
        icon: <Campaign />,
      });
    }

    if (hasAccess('Sec:Auditoría')) {
      administrationSection.items.push({
        title: _(msg`Auditoría`),
        path: '/audit',
        icon: <Biotech fontSize="small" />,
      });
    }

    if (hasAccess('Sec:Billing')) {
      administrationSection.items.push({
        title: _(msg`Facturación`),
        path: '/billing',
        icon: <Payment fontSize="small" />,
      });
    }

    if (hasAccess('Sec:HistorialNotificacionesAlertas')) {
      amaruSection.items.push({
        title: _(msg`Alertas`) + ' (Beta)',
        path: '/alerts',
        icon: <Warning fontSize="small" />,
      });
    }
    
    if (hasAccess('Sec:ConfiguracionEquipos')) {
      administrationSection.items.push({
        title: _(msg`Configuración de Equipos`),
        path: '/device-configuration',
        icon: <FolderIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:StandardDesign')) {
      administrationSection.items.push({
        title: _(msg`Diseño Amaru`),
        path: '/standard-design',
        icon: <DesignServicesOutlined fontSize="small" />,
      });
    }

    if (hasAccess('Sec:DispositivosPublicos')) {
      administrationSection.items.push({
        title: _(msg`Dispositivos Públicos`),
        path: '/access-off-login',
        icon: <BrowserNotSupportedIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:EnvioComandos')) {
      administrationSection.items.push({
        title: _(msg`Envío de Comandos`),
        path: '/device-commands',
        icon: <ScheduleSendIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:Notificaciones')) {
      administrationSection.items.push({
        title: _(msg`Notificaciones`),
        path: '/notifications',
        icon: <Notifications fontSize="small" />,
      });
    }

    if (hasAccess('Sec:RedDeCanales')) {
      administrationSection.items.push({
        title: _(msg`Red de Canales`),
        path: '/irrigations',
        icon: <CodeOffIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:Organizacion')) {
      administrationSection.items.push({
        title: _(msg`Organización`),
        path: '/organization',
        icon: <BusinessIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:PermisoAccesos')) {
      administrationSection.items.push({
        title: _(msg`Permisos y Accesos`),
        path: '/permits_access',
        icon: <WifiProtectedSetupOutlinedIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:Sistemas')) {
      administrationSection.items.push({
        title: _(msg`Sistemas`),
        path: '/systems',
        paramsPath: '/system/administrator/:systemId',
        icon: <DeviceHubIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:API')) {
      administrationSection.items.push({
        title: _(msg`API Capta`),
        path: '/api',
        paramsPath: ['/api/keys'],
        icon: <KeyIcon fontSize="small" />,
      });
    }

    if (hasAccess('Sec:Usuarios')) {
      if(isCaptaUser){
        administrationSection.items.push({
          title: _(msg`Usuarios`),
          path: '/users',
          icon: <GroupIcon fontSize="small" />,
        });
      }else {
        administrationSection.items.push({
          title: _(msg`Usuarios`),
          path: '/users-administration',
          icon: <GroupIcon fontSize="small" />,
        });
      }
    }

    // if (hasAccess('Sec:CalidadAgua')) {
    //   amaruSection.items.push({
    //     title: 'Calidad de Agua',
    //     path: '/water-quality',
    //     icon: <OpacityIcon fontSize="small" />,
    //   });
    // }

    if (amaruSection.items.length > 0) {
      newSection.push(amaruSection);
    }
    if (amaruControl.items.length > 0) {
      newSection.push(amaruControl);
    }
    if (topologySection.items.length > 0) {
      newSection.push(topologySection);
    }
    if (administrationSection.items.length > 0) {
      newSection.push(administrationSection);
    }
    setSections(newSection);
  }, [auth, _]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <>
          <Box
            sx={{
              display: {
                lg: 'none',
                xs: 'flex',
              },
              justifyContent: 'center',
              p: 2,
            }}
          >
            <RouterLink to="/">
              <LogoCapta />
            </RouterLink>
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            {sections.map((section) => (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 3,
                  },
                }}
                {...section}
              />
            ))}
          </Box>
        </>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 250,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
