import { Control, Path, Controller, FieldValues } from 'react-hook-form';
import FileInput from 'src/components/FileInput';

interface Props<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
}

const FileInputController = <T extends FieldValues>({ name, label, control }: Props<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return <FileInput {...field} label={label} />;
      }}
    />
  );
};

export default FileInputController;
