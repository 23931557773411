import { t } from '@lingui/macro';
import * as yup from 'yup';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{10,}$/; // 10 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character
const phoneRegex = /^\d{9,15}$/; // 912345678

const organizationSchema = yup.object({
  data: yup
    .object({
      id: yup.number().required(),
      name: yup.string().required(),
    })
    .typeError(t`La organización es requerida.`)
    .required(),
  role: yup
    .object({
      id: yup.number().required(),
      name: yup.string().required(),
    })
    .typeError(t`El rol es requerido.`)
    .required(),
});

export const schema = (editing: boolean = false) => {
  const passwordSchema = yup
    .string()
    .matches(
      passwordRegex,
      t`La contraseña debe tener al menos 10 caracteres, una mayúscula, una minúscula, un número y un caracter especial.`,
    );
  const confirmPasswordSchema = yup
    .string()
    .oneOf([yup.ref('password'), null], t`Las contraseñas deben coincidir.`);
  return yup.object({
    organization: organizationSchema,
    organizations: yup.array().of(organizationSchema),
    name: yup.string().required(t`El nombre es requerido.`),
    lastnames: yup.string().required(t`Los apellidos son requeridos.`),
    username: yup
      .string()
      .email()
      .required(t`El correo electrónico es requerido.`),
    phone_number: yup.string().matches(phoneRegex, t`El teléfono debe ser un número válido.`),
    code: yup.object().required(t`El código de país es requerido.`),
    password: editing
      ? passwordSchema.transform((value) => value || null).nullable()
      : passwordSchema.required(t`La contraseña es requerida.`),
    confirmPassword: editing
      ? confirmPasswordSchema.transform((value) => value || null).nullable()
      : confirmPasswordSchema.required(t`La confirmación de contraseña es requerida.`),
    ...(!editing && { checkbox_email: yup.boolean() }),
    // picture is a url
    picture: yup
      .mixed()
      .nullable()
      .test('fileSize', t`La imagen debe pesar menos de 5MB.`, (value) => {
        if (!value) return true;
        if (typeof value === 'string') return true;
        return value.size <= 5 * 1024 * 1024;
      })
      .test('fileType', t`La imagen debe ser un archivo de tipo JPG, JPEG o PNG.`, (value) => {
        if (!value) return true;
        if (typeof value === 'string') return true;
        return value.type === 'image/jpeg' || value.type === 'image/png';
      }),
  });
};
