import { Autocomplete, ChipTypeMap, TextField, type AutocompleteProps } from '@mui/material';
import React, { forwardRef } from 'react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { callingCode } from './utils/callingCode';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';

interface CallingCode {
  country: string;
  calling_code: string;
  abbreviation: string;
}

interface Props<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> extends Omit<
    AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    'onChange' | 'options' | 'renderInput'
  > {
  name?: string | undefined;
  label?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (value: Value | null) => void;
}

const CountryCallingAutocomplete = forwardRef<HTMLInputElement, Props<CallingCode>>(
  (props, ref) => {
    const { _ } = useLingui();

    const {
      error,
      value,
      helperText,
      label = _(msg`Código de país`),
      onChange = () => {},
      ...autocompleteProps
    } = props;

    return (
      <Autocomplete<CallingCode>
        {...autocompleteProps}
        ref={ref}
        defaultValue={value}
        options={callingCode ?? []}
        isOptionEqualToValue={(option, value) =>
          option != null && value != null && option?.calling_code == value?.calling_code
        }
        onChange={(_, value) => onChange(value)}
        getOptionLabel={(code) =>
          code != null
            ? `${getUnicodeFlagIcon(code?.abbreviation ?? 'ZZ')} ${code?.calling_code}`
            : '---'
        }
        renderInput={(params) => (
          <>
            <TextField {...params} label={label} error={error} helperText={helperText} />
          </>
        )}
      />
    );
  },
);

export default CountryCallingAutocomplete;
