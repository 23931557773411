import { useDispatch, useSelector } from '@/store';
import { Dialog } from '@mui/material';
import { Suspense } from 'react';
import { setUserProfileModal } from 'src/slices/modals';
import UserProfile from './UserProfile';
import UserProfileSkeleton from './UserProfileSkeleton';

const UserProfileModal = () => {
  const dispatch = useDispatch();
  const { userProfileModal } = useSelector((state) => state.modals_store);

  const onClose = () => {
    dispatch(
      setUserProfileModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog open={userProfileModal.open} onClose={onClose} maxWidth="sm" fullWidth>
      <Suspense fallback={<UserProfileSkeleton />}>
        {userProfileModal.open && (
          <UserProfile userId={userProfileModal.userId} onClose={onClose} />
        )}
      </Suspense>
    </Dialog>
  );
};

export default UserProfileModal;
