import { useEffect } from 'react';
import GET_AUTHORIZATION from '../graphql/queries/getAuthorization';
import { client } from '@/graphql';
import GET_DEVICE_AUTHORIZATION from '../graphql/queries/getDeviceAuthorization';

const POLL_INTERVAL = 5 * 60 * 1000; // 5 minutes

/**
 * INSTANCE IT ONLY ONCE. Makes a refetch every X min so the authorization access are updated
 */
const useAuthorizationRefetch = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      client.refetchQueries({ include: [GET_AUTHORIZATION, GET_DEVICE_AUTHORIZATION] });
    }, POLL_INTERVAL);
    return () => clearInterval(interval);
  }, []);
};

export { useAuthorizationRefetch };
