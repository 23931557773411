import { setSimulateUserModal } from '@/slices/modals';
import { useDispatch, useSelector } from '@/store';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { Access, AuthorizationWrapper } from '@features/authorization';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Dialog, DialogContent, Skeleton } from '@mui/material';
import { FormEventHandler, Suspense, type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useModalStatusMessage } from 'src/hooks';
import SIMULATE_USER from '../../graphql/mutations/simulateUser';
import GET_SIMULATE_USER_MODAL_INFO from '../../graphql/queries/getSimulateUserModalInfo';

interface Props {
  userId: number;
  onClose: () => void;
}

const Component: FC<Props> = ({ onClose, userId }) => {
  const navigate = useNavigate();
  const { _ } = useLingui();
  const { openModalErrorMessage } = useModalStatusMessage();
  const { simulate, isSimulating, stopUserSimulation } = useAuth();
  const { data } = useSuspenseQuery(GET_SIMULATE_USER_MODAL_INFO, {
    variables: {
      userId,
    },
  });
  const [simulateMutation, { loading }] = useMutation(SIMULATE_USER, {
    onCompleted: async (data) => {
      const { impersonateUser: userToImpersonate } = data;
      if (!userToImpersonate) {
        openModalErrorMessage(_(msg`No se pudo simular al usuario`));
        return;
      }
      if (isSimulating) {
        stopUserSimulation();
      }
      simulate(userToImpersonate);
      navigate({
        search: '',
      });
      navigate(0);
    },
  });

  const { fullName } = data.user;

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    await simulateMutation({
      variables: {
        userId,
      },
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <DialogContent dividers>
        <Trans>
          ¿Estás seguro que deseas simular al usuario <b>{fullName}</b>?
        </Trans>
      </DialogContent>
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        submitLoading={loading}
        submitLabel={_(msg`Simular`)}
      />
    </form>
  );
};

const SimulateUserModal: FC = () => {
  const dispatch = useDispatch();
  const { _ } = useLingui();
  const { simulateUserModal } = useSelector((state) => state.modals_store);

  const onClose = () => {
    dispatch(
      setSimulateUserModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog open={simulateUserModal.open} onClose={onClose}>
      <ModalTitle onClose={onClose} title={_(msg`Simular usuario`)} />
      <Suspense
        fallback={
          <Box>
            <Skeleton variant="rounded" height={100} />
            <ModalActions onClose={onClose} />
          </Box>
        }
      >
        <AuthorizationWrapper onClose={onClose} access={Access.SimulateUserModal}>
          {simulateUserModal.open && (
            <Component onClose={onClose} userId={simulateUserModal.userId} />
          )}
        </AuthorizationWrapper>
      </Suspense>
    </Dialog>
  );
};

export default SimulateUserModal;
