import { setDeleteUserModal } from '@/slices/modals';
import { useDispatch, useSelector } from '@/store';
import { useMutation } from '@apollo/client';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { Access, AuthorizationWrapper } from '@features/authorization';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Dialog, DialogContent } from '@mui/material';
import { FormEventHandler, Suspense, type FC } from 'react';
import { useModalStatusMessage } from 'src/hooks';
import DELETE_USER from '../../graphql/mutations/deleteUser';
import GET_USER_LIST from '../../graphql/queries/getUserList';

interface Props {
  userId: number;
  onClose: () => void;
}

const Component: FC<Props> = ({ onClose, userId }) => {
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { _ } = useLingui();
  const [deleteUser, { loading }] = useMutation(DELETE_USER, {
    onError(error) {
      openModalErrorMessage(error.message);
    },
    onCompleted: () => {
      onClose();
      openModalSuccessMessage(_(msg`Usuario eliminado correctamente`));
    },
    refetchQueries: [GET_USER_LIST],
  });

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    deleteUser({
      variables: {
        input: {
          userId,
        },
      },
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <DialogContent dividers>
        <Trans>
          ¿Estás seguro que deseas eliminar al usuario con id <b>{userId}</b>?
        </Trans>
      </DialogContent>
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        submitLoading={loading}
        submitLabel={_(msg`Eliminar`)}
        submitColor="error"
      />
    </form>
  );
};

const DeleteUserModal: FC = () => {
  const dispatch = useDispatch();
  const { _ } = useLingui();
  const { deleteUserModal } = useSelector((state) => state.modals_store);

  const onClose = () => {
    dispatch(
      setDeleteUserModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog open={deleteUserModal.open} onClose={onClose}>
      <ModalTitle onClose={onClose} title={_(msg`Eliminar usuario`)} />
      <Suspense>
        <AuthorizationWrapper onClose={onClose} access={Access.DeleteUserModal}>
          {deleteUserModal.open && <Component onClose={onClose} userId={deleteUserModal.userId} />}
        </AuthorizationWrapper>
      </Suspense>
    </Dialog>
  );
};

export default DeleteUserModal;
