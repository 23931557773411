import { skipToken, useSuspenseQuery } from '@apollo/client';
import GET_AUTHORIZATION from '../graphql/queries/getAuthorization';
import { Access } from '../utils/accessEnum';
import { validateAccess } from '../utils/validateAccess';
import GET_DEVICE_AUTHORIZATION from '../graphql/queries/getDeviceAuthorization';

const useSuspenseAuthorization = (deviceId?: string) => {
  const { data } = useSuspenseQuery(GET_AUTHORIZATION);
  const { data: deviceAuthorizationData } = useSuspenseQuery(
    GET_DEVICE_AUTHORIZATION,
    deviceId != null
      ? {
          variables: {
            input: {
              deviceId,
            },
          },
        }
      : skipToken,
  );
  return {
    validateAccess: (access: Access) =>
      validateAccess(
        data.authorization,
        deviceAuthorizationData?.authorization?.authorizationDevice,
        access,
        deviceId,
      ),
  };
};

export { useSuspenseAuthorization };
