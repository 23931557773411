import { type FC, type PropsWithChildren, type ReactNode } from 'react';
import { useSuspenseAuthorization } from '../hooks/useSuspenseAuthorization';
import { Access } from '../utils/accessEnum';
import { Unauthorized } from './Unauthorized';

interface Props extends PropsWithChildren {
  access?: Access | null;
  deviceId?: string;
  onClose?: () => void;
  /** Fallback component to show when the user is unauthorized */
  fallback?: ReactNode;
}

const AuthorizationWrapper: FC<Props> = ({ access, children, deviceId, onClose, fallback }) => {
  const { validateAccess } = useSuspenseAuthorization(deviceId);
  if (access == null || validateAccess(access)) {
    return children;
  }
  return fallback !== undefined ? fallback : <Unauthorized onClose={onClose} />;
};

export { AuthorizationWrapper };
