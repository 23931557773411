import { gql } from '@/__generated__';
import { type StandardAutocompleteDataQuery } from '@/__generated__/graphql';

export type StandardAutocompleteIrrigation = NonNullable<
  StandardAutocompleteDataQuery['irrigations']
>[number];
export type StandardAutocompleteDevice = NonNullable<
  StandardAutocompleteDataQuery['devices']
>[number];
export type StandardAutocompleteOrganization = NonNullable<
  StandardAutocompleteDataQuery['organizations']
>[number];

const STANDARD_AUTOCOMPLETE_DATA = gql(`#graphql
  query StandardAutocompleteData(
    $devicesInput: DevicesInput
    $organizationsInput: OrganizationsInput
    $usersInput: UsersInput
    $projectsInput: ProjectsInput
    $includeDevices: Boolean!
    $includeOrganizations: Boolean!
    $includeIrrigations: Boolean!
    $includeUsers: Boolean!
    $includeProjects: Boolean!
    $includeCurrencies: Boolean!
    $includeServiceTypes: Boolean!
  ) {
    devices(input: $devicesInput) @include(if: $includeDevices) {
      id
      profile {
        id
        name
      }
    }
    organizations(input: $organizationsInput) @include(if: $includeOrganizations) {
      id
      name
    }
    irrigations @include(if: $includeIrrigations) {
      id
      name
    }
    users(input: $usersInput) @include(if: $includeUsers) {
      id
      name
      lastnames
    }
    projects(input: $projectsInput) @include(if: $includeProjects) {
      id
    }
    currencies @include(if: $includeCurrencies) {
      id
      name
    }
    serviceTypes @include(if: $includeServiceTypes) {
      id
      name
    }
  }
`);

export default STANDARD_AUTOCOMPLETE_DATA;
