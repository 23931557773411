import { gql } from '@/__generated__';

const DELETE_USER = gql(`#graphql
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      userId
    }
  }
`);

export default DELETE_USER;
