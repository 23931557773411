import { gql } from '@/__generated__';

const GET_SIMULATE_USER_MODAL_INFO = gql(`#graphql
  query GetSimulateUserModalInfo($userId: Int!) {
    user(input: { id: $userId }) {
      fullName
      email
      id
    }
  }
`);

export default GET_SIMULATE_USER_MODAL_INFO;
