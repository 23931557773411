import { useModalStatusMessage } from '@/hooks';
import { useDispatch, useSelector } from '@/store';
import { useMutation } from '@apollo/client';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { FormEventHandler, type FC } from 'react';
import { setExtendTemporaryUserTokenModal } from 'src/slices/modals';
import EXTEND_TEMPORARY_USER_TOKEN from '../../graphql/mutations/extendTemporaryUserToken';
import GET_USER_LIST from '../../graphql/queries/getUserList';

const ExtendTemporaryUserTokenModal: FC = () => {
  const dispatch = useDispatch();
  const { _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { open, userId } = useSelector((state) => state.modals_store.extendTemporaryUserTokenModal);
  const [extend] = useMutation(EXTEND_TEMPORARY_USER_TOKEN, {
    onCompleted() {
      openModalSuccessMessage(_(msg`Se ha extendido el tiempo de expiración del token`));
      onClose();
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
    refetchQueries: [GET_USER_LIST],
    update(cache) {
      cache.modify({
        id: cache.identify({
          __typename: 'User2',
          id: userId,
        }),
        fields: {
          temporaryUser(_, { INVALIDATE }) {
            return INVALIDATE;
          },
        },
      });
    },
  });

  if (!userId) return null;

  const onClose = () => {
    dispatch(setExtendTemporaryUserTokenModal({ open: false }));
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    extend({
      variables: {
        input: {
          userId,
        },
      },
    });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <form onSubmit={onSubmit}>
          <ModalTitle title={_(msg`Extender duración del token de activación`)} />
          <DialogContent dividers>
            <Typography>
              <Trans>
                El nuevo tiempo de expiración del token será en 2 semanas. ¿Deseas continuar?
              </Trans>
            </Typography>
          </DialogContent>
          <ModalActions
            onClose={onClose}
            closeButton={{ label: _(msg`Cancelar`) }}
            onSubmit="submit"
            submitLabel={_(msg`Extender`)}
          />
        </form>
      </Dialog>
    </>
  );
};

export default ExtendTemporaryUserTokenModal;
