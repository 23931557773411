import { gql } from '@/__generated__';

const GET_DEVICE_PROFILE_AUDIT_LOGS = gql(`#graphql
  query DeviceProfile_GetDeviceAuditLogs($input: AuditLogsInput, $pagination: DynamoPaginationInput) {
  auditLogs(input: $input, pagination: $pagination) {
    items {
      id
      action {
        type
      }
      entity {
        id
        type
      }
      responsible {
        id
        type
        displayName
      }
      timestamp
    }
    pageInfo {
      count
      lastEvaluatedKey
    }
  }
}
`);

export default GET_DEVICE_PROFILE_AUDIT_LOGS;
