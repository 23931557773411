import { useSuspenseQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import { Box, Button, Skeleton, Tooltip, TooltipProps, Typography } from '@mui/material';
import { Suspense, type FC } from 'react';
import DEVICE_TOOLTIP_GET_USER_INFO from '@/graphql/querys/tooltips/getDeviceInfo';
import ApolloErrorBoundary from '../ApolloErrorBoundary';
import { useDispatch } from 'react-redux';
import { openDeviceProfileModal } from 'src/features/deviceProfile/slices/deviceProfileSlice';

type Props = Omit<TooltipProps, 'title'> & {
  deviceId: string;
  title?: TooltipProps['title'] | undefined;
};

const DeviceInfo: FC<{ deviceId: string }> = ({ deviceId }) => {
  const dispatch = useDispatch();
  const { data } = useSuspenseQuery(DEVICE_TOOLTIP_GET_USER_INFO, {
    variables: {
      deviceId,
    },
  });

  const openDeviceProfile = () => {
    dispatch(
      openDeviceProfileModal({
        deviceId,
      }),
    );
  };

  return (
    <Box>
      <Typography sx={{ fontSize: 'inherit' }} fontWeight="bold">
        {data.device.profile.name}
      </Typography>
      <Typography sx={{ fontSize: 'inherit' }}>ID: {deviceId}</Typography>
      <Typography sx={{ fontSize: 'inherit' }}>
        ORG:{' '}
        {data.device.profile.organization?.name ?? (
          <i>
            <Trans>Sin organización</Trans>
          </i>
        )}
      </Typography>
      <Button
        fullWidth
        size="small"
        sx={{ fontSize: 'inherit', mt: 1, lineHeight: 1 }}
        variant="contained"
        color="info"
        onClick={openDeviceProfile}
      >
        <Trans>Abrir perfil</Trans>
      </Button>
    </Box>
  );
};

const DeviceTooltip: FC<Props> = ({ deviceId, ...props }) => {
  return (
    <Tooltip
      {...props}
      title={
        <ApolloErrorBoundary
          fallback={() => (
            <i>
              <Trans>No se ha encontrado el dispositivo con id {deviceId}</Trans>
            </i>
          )}
        >
          <Suspense fallback={<Skeleton width={50} />}>
            <DeviceInfo deviceId={deviceId} />
          </Suspense>
        </ApolloErrorBoundary>
      }
    />
  );
};

export default DeviceTooltip;
