import { useModalStatusMessage } from '@/hooks';
import { useSelector } from '@/store';
import { theme } from '@/theme';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import { AuthorizationWrapper, Access } from '@features/authorization';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Dialog, DialogContent, DialogContentText, Skeleton, TextField } from '@mui/material';
import { Suspense, useState, type FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { z } from 'zod';
import CLONE_DEVICE from '../../graphql/mutations/cloneDevice';
import GET_CLONE_MODAL_FIELDS from '../../graphql/queries/getCloneDeviceModalFields';
import { setCloneDeviceModal } from '../../slices/deviceConfigurationSlice';

interface Props {
  deviceId: string;
  onClose: () => void;
}

const Component: FC<Props> = ({ onClose, deviceId }) => {
  const { _ } = useLingui();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const { data } = useSuspenseQuery(GET_CLONE_MODAL_FIELDS, {
    variables: {
      deviceId,
    },
  });
  const [cloneDevice] = useMutation(CLONE_DEVICE, {
    onCompleted({ cloneDevice: clonedDevice }) {
      openModalSuccessMessage(
        _(msg`Se ha creado un dispositivo clonado con id ${clonedDevice.id}`),
      );
      onClose();
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
    update(cache) {
      cache.modify({
        fields: {
          devicesConnection(_, { DELETE }) {
            return DELETE;
          },
          devices(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
  });

  const schema = z.object({
    deviceName: z
      .string()
      .min(2, _(msg`El nombre del dispositivo debe tener al menos 2 caracteres`)),
  });

  type FormValues = z.infer<typeof schema>;

  const methods = useForm<FormValues>({
    defaultValues: {
      deviceName: `Clon ${data.device.profile.name}`,
    },
    resolver: zodResolver(schema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const { deviceName } = formData;
    cloneDevice({
      variables: {
        deviceId,
        name: deviceName,
      },
    });

    onClose();
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        submitButton={{
          type: 'submit',
        }}
      />
      <DialogContent dividers>
        <DialogContentText gutterBottom>
          <Trans>El dispositivo se clonará con el siguiente nombre</Trans>
        </DialogContentText>
        <Controller
          name="deviceName"
          control={methods.control}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              {...field}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </DialogContent>
      <ModalActions
        onClose={onClose}
        onResetForm={() => methods.reset()}
        submitButton={{
          label: _(msg`Clonar`),
          disabled: !methods.formState.isValid,
          onClick: () => {
            setOpenConfirmationModal(true);
          },
        }}
      />
    </form>
  );
};

const Fallback: FC = () => {
  return (
    <DialogContent>
      <Skeleton height={100} variant="rounded" />
    </DialogContent>
  );
};

const CloneDeviceModal: FC = () => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const { cloneDeviceModal: modalState } = useSelector((state) => state.device_configuration_store);

  const onClose = () => {
    dispatch(setCloneDeviceModal({ open: false }));
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={modalState.open}
      onClose={onClose}
      transitionDuration={{
        enter: theme.transitions.duration.enteringScreen,
        exit: 0,
      }}
    >
      <ModalTitle title={_(msg`Clonar dispositivo`)} onClose={onClose} />
      <ApolloErrorBoundary>
        <Suspense fallback={<Fallback />}>
          {modalState.open && (
            <AuthorizationWrapper
              access={Access.DeleteDeviceModal}
              deviceId={modalState.deviceId}
              onClose={onClose}
            >
              <Component deviceId={modalState.deviceId} onClose={onClose} />
            </AuthorizationWrapper>
          )}
        </Suspense>
      </ApolloErrorBoundary>
    </Dialog>
  );
};

export default CloneDeviceModal;
