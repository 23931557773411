import {
  CreateDgaConfigurationMutationVariables,
  DgaFrequency,
  DgaType,
} from '@/__generated__/graphql';
import { useModalStatusMessage } from '@/hooks';
import { useDispatch, useSelector } from '@/store';
import { skipToken, useMutation, useSuspenseQuery } from '@apollo/client';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import StandardAutocompleteIdsWithFallback from '@features/standardDesign/components/autocomplete/StandardAutocompleteIds';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Info, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Suspense, useState, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CREATE_DGA_CONFIGURATION from '../../graphql/mutations/createDgaConfiguration';
import GET_DGA_CONFIGURATION from '../../graphql/queries/getDGAConfiguration';
import {
  setCreateDGAConfigurationModal,
  setUpdateDGAConfigurationModal,
} from '../../slices/dgaSlice';
import { getCreateDgaConfigurationMutationSchema } from '../../utils/validationSchemas';
import { AuthorizationWrapper, Access } from '@features/authorization';
import DgaScheduleController from './controllers/DgaScheduleController';

type FormValues = CreateDgaConfigurationMutationVariables;

const Component: FC<{ onClose: () => void; deviceId?: string }> = ({
  onClose,
  deviceId: preSelectedDeviceId,
}) => {
  const { _, i18n } = useLingui();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const methods = useForm<FormValues>({
    defaultValues: {
      deviceId: preSelectedDeviceId ?? '',
      input: {
        code: '',
        rut: '',
        password: '',
        type: DgaType.Surface,
      },
      scheduleInput: {
        enable: false,
        frequency: DgaFrequency.Hourly,
      },
    },
    resolver: zodResolver(getCreateDgaConfigurationMutationSchema(i18n)),
  });

  const deviceId = methods.watch('deviceId');

  const { data } = useSuspenseQuery(
    GET_DGA_CONFIGURATION,
    deviceId ? { variables: { deviceId } } : skipToken,
  );

  const [createDgaConfiguration, { loading }] = useMutation(CREATE_DGA_CONFIGURATION, {
    onError: (error) => {
      openModalErrorMessage(error.message);
    },
    onCompleted: () => {
      openModalSuccessMessage(_(msg`Configuración DGA creada correctamente`));
      onClose();
    },
    update(cache) {
      cache.modify({
        fields: {
          devicesConnection(_, { INVALIDATE }) {
            return INVALIDATE;
          },
          devices(_, { INVALIDATE }) {
            return INVALIDATE;
          },
        },
      });
    },
    onQueryUpdated(observableQuery) {
      if (observableQuery.queryName !== 'StandardAutocompleteData') {
        return observableQuery.refetch();
      }
    },
  });

  const hasDGAConfiguration = data?.device?.dgaConfiguration != null;

  const resetForm = () => {
    methods.reset();
  };

  const onSubmit = async (values: FormValues) => {
    const { deviceId, input, scheduleInput } = values;
    await createDgaConfiguration({
      variables: {
        deviceId,
        input,
        scheduleInput,
      },
    });
  };

  const handleEditConfiguration = () => {
    dispatch(
      setCreateDGAConfigurationModal({
        open: false,
      }),
    );
    dispatch(
      setUpdateDGAConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogContent dividers>
        <Controller
          name="deviceId"
          control={methods.control}
          render={({ field, fieldState }) => (
            <StandardAutocompleteIdsWithFallback
              dataType="devices"
              label={_(msg`Dispositivo`)}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              value={field.value || null}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
        {hasDGAConfiguration && !loading ? (
          <Box mt={2}>
            <Box display="flex" gap={1} alignItems="center" mb={1}>
              <Info fontSize="small" color="warning" />
              <Typography>
                <Trans>El dispositivo ya tiene una configuración DGA</Trans>
              </Typography>
            </Box>
            <Button variant="contained" color="primary" onClick={handleEditConfiguration}>
              <Trans>Editar configuración</Trans>
            </Button>
          </Box>
        ) : (
          <>
            <Box
              display="grid"
              gridTemplateColumns={{
                xs: '1fr',
                md: '1fr 1fr',
              }}
              gap={2}
              mt={2}
            >
              <Controller
                name="input.type"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    options={Object.values(DgaType)}
                    disableClearable
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(value) => {
                      if (value === DgaType.Surface) return _(msg`Superficial`);
                      if (value === DgaType.PipeSurface) return _(msg`Superficial con flujómetro`);
                      if (value === DgaType.Underground) return _(msg`Subterránea`);
                      return value;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={_(msg`Tipo de extracción`)}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="input.code"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={_(msg`Código DGA`)}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="input.rut"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={_(msg`RUT`)}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="input.password"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label={_(msg`Contraseña`)}
                    autoComplete="new-password"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword((showPassword) => !showPassword)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <DgaScheduleController control={methods.control} />
          </>
        )}
      </DialogContent>
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        onResetForm={resetForm}
        dirtyForm={hasDGAConfiguration ? false : methods.formState.isDirty}
        submitLoading={loading}
      />
    </form>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => (
  <>
    <DialogContent dividers>
      <Skeleton variant="rounded" height={400} />
    </DialogContent>
    <ModalActions onClose={onClose} />
  </>
);

const CreateDGAConfigurationModal: FC = () => {
  const dispatch = useDispatch();
  const { createDGAConfigurationModal } = useSelector((state) => state.dga_store);

  const onClose = () => {
    dispatch(
      setCreateDGAConfigurationModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog onClose={onClose} open={createDGAConfigurationModal.open} maxWidth="md" fullWidth>
      <ModalTitle onClose={onClose} title={<Trans>Crear configuración DGA</Trans>} />
      <Suspense fallback={<Fallback onClose={onClose} />}>
        <AuthorizationWrapper onClose={onClose} access={Access.CreateDGAConfigurationModal}>
          <Component onClose={onClose} deviceId={createDGAConfigurationModal.deviceId} />
        </AuthorizationWrapper>
      </Suspense>
    </Dialog>
  );
};

export default CreateDGAConfigurationModal;
