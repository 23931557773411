import NotFound from '@/pages/NotFound';
import ServerError from '@/pages/ServerError';
import AuthorizationRequired from '@/pages/AuthorizationRequired';
import { AmaruGuard, AuthGuard, GuestGuard } from '@components/guards';
import { redirect, type RouteObject } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import { Component as Login } from './pages/authentication';

const routes: RouteObject[] = [
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: 'report-download',
    lazy: () => import('src/pages/report-download'),
  },
  {
    // should change path to /distribution/history/public/:distributionInstanceToken
    path: 'distribution-history/public/:distributionInstanceToken',
    // should change file path to @/pages/distribution/history/public/[distributionInstanceToken].tsx
    lazy: () => import('@/pages/distribution/public'),
  },
  {
    path: 'organization/charts/:name',
    lazy: () => import('@/pages/organization-charts/[name]'),
  },
  {
    path: 'change-password/:token',
    lazy: () => import('@/pages/change-password'),
  },
  {
    path: 'activate-user',
    lazy: () => import('@/pages/activate-user'),
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        // this path match / and /control-center
        path: 'control-center?',
        element: <AmaruGuard section="Sec:CentroControl" />,
        children: [
          {
            index: true,
            lazy: () => import('src/pages/control-center'),
          },
        ],
      },
      {
        path: 'notifications',
        element: <AmaruGuard section="Sec:Notificaciones" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/notifications'),
          },
        ],
      },
      {
        path: 'devices',
        element: <AmaruGuard section="Sec:Dispositivos" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/devices2'),
          },
          {
            path: ':id/*',
            lazy: () => import('@/pages/devices/[id]'),
          },
        ],
      },
      {
        path: 'devices2',
        element: <AmaruGuard section="Sec:Dispositivos" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/devices2'),
          },
        ],
      },
      {
        path: 'announcements',
        element: <AmaruGuard section="Sec:Anuncios" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/announcements'),
          },
          {
            path: ':announcementId',
            lazy: () => import('@/pages/announcements/[announcementId]'),
          },
        ],
      },
      {
        path: 'irrigations',
        element: <AmaruGuard section="Sec:RedDeCanales" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/irrigations'),
          },
        ],
      },
      // should be 'organizations' instead of 'organization'?
      {
        path: 'organization',
        element: <AmaruGuard section="Sec:Organizacion" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/organizations'),
          },
        ],
      },
      {
        path: 'dga',
        element: <AmaruGuard section="Sec:ReportesDGA" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/dga'),
          },
        ],
      },
      {
        path: 'integrations',
        element: <AmaruGuard section="Sec:Integraciones" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/integrations'),
          },
        ],
      },
      {
        path: 'analysis-stations',
        children: [
          {
            index: true,
            loader: () => redirect('/devices-analysis'),
          },
          {
            path: ':deviceId',
            loader: (props) => redirect(`/devices-analysis/${props.params.deviceId}`),
          },
        ],
      },
      {
        path: 'devices-analysis',
        element: <AmaruGuard section="Sec:AnalisisEstaciones" />,
        children: [
          {
            index: true,
            lazy: () => import('src/pages/devices-analysis'),
          },
          {
            path: ':deviceId',
            lazy: () => import('src/pages/devices-analysis'),
          },
        ],
      },
      {
        path: 'advanced-devices-analysis',
        element: <AmaruGuard section="Sec:AnalisisAvanzado" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/advanced-devices-analysis'),
          },
        ],
      },
      {
        path: 'systems',
        element: <AmaruGuard section="Sec:Sistemas" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/systems'),
          },
          {
            path: 'administrator/:systemId',
            lazy: () => import('@/pages/systems/administrator'),
          },
        ],
      },
      {
        path: 'gates',
        element: <AmaruGuard section="Sec:Compuertas" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/gates'),
          },
          {
            path: 'administration/:gateId',
            lazy: () => import('@/pages/gates/administration/[gateId]'),
          },
        ],
      },
      // distribution should normalize the path using distribution/* pattern.
      {
        // change path to 'distribution'
        path: 'distribution-initialization',
        element: <AmaruGuard section="Sec:Reparto" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/distribution'),
          },
        ],
      },
      {
        // change path to 'distribution/maipo'
        path: 'distribution-maipo',
        element: <AmaruGuard section="Sec:CalculadoraMaipo" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/distribution/maipo'),
          },
        ],
      },
      {
        // change path to 'distribution/history'
        path: 'distribution-history',
        element: <AmaruGuard section="Sec:Reparto" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/distribution/history'),
          },
          {
            path: ':success',
            lazy: () => import('@/pages/distribution/history'),
          },
          {
            path: 'section/:sectionId',
            lazy: () => import('@/pages/distribution/history'),
          },
        ],
      },
      {
        path: 'distribution/canals',
        element: <AmaruGuard section="Sec:Canales" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/distribution-canals'),
          },
        ],
      },
      {
        path: 'users-administration',
        element: <AmaruGuard section="Sec:Usuarios" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/users-administration'),
          },
        ],
      },
      {
        path: 'users',
        element: <AmaruGuard section="Sec:Usuarios" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/users'),
          },
        ],
      },
      {
        path: 'api',
        element: <AmaruGuard section="Sec:API" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/api'),
          },
          {
            path: 'keys',
            lazy: () => import('@/pages/api/keys'),
          },
        ],
      },
      {
        path: 'reports',
        element: <AmaruGuard section="Sec:Reports" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/reports/data-extraction2'),
          },
          {
            path: 'create',
            lazy: () => import('@/pages/reports/create'),
          },
          {
            path: 'edit',
            children: [
              {
                path: ':templateId',
                lazy: () => import('@/pages/reports/edit'),
              },
            ],
          },
        ],
      },
      {
        path: 'device-commands',
        element: <AmaruGuard section="Sec:EnvioComandos" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/device-commands'),
          },
          {
            path: 'advanced',
            lazy: () => import('@/pages/device-commands/advanced'),
          },
          {
            path: 'calendar',
            lazy: () => import('@/pages/device-commands/calendar'),
          },
        ],
      },

      {
        path: 'device-configuration',
        element: <AmaruGuard section="Sec:ConfiguracionEquipos" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/device-configuration'),
          },
        ],
      },
      {
        path: 'access-off-login',
        element: <AmaruGuard section="Sec:DispositivosPublicos" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/access-off-login'),
          },
        ],
      },
      {
        path: 'permits_access',
        element: <AmaruGuard section="Sec:PermisoAccesos" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/permits-access'),
          },
        ],
      },
      {
        path: 'support',
        element: <AmaruGuard section="Sec:Soporte" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/support'),
          },
        ],
      },
      {
        path: 'metrics-views',
        element: <AmaruGuard section="Sec:MetricsViews" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/metrics-views'),
          },
          {
            path: ':id',
            lazy: () => import('@/pages/metrics-views/[id]'),
          },
        ],
      },
      {
        path: 'standard-design',
        element: <AmaruGuard section="Sec:StandardDesign" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/standard-design'),
          },
        ],
      },
      {
        path: 'water-quality',
        element: <AmaruGuard section="Sec:CalidadAgua" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/water-quality'),
          },
        ],
      },
      {
        path: 'audit',
        element: <AmaruGuard section="Sec:Auditoría" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/audit'),
          },
        ],
      },
      {
        path: 'billing',
        element: <AmaruGuard section="Sec:Billing" />,
        children: [
          {
            path: 'payments/update/:id',
            children: [
              {
                index: true,
                lazy: () => import('@/pages/billing/UpdatePaymentView'),
              },
            ],
          },
          {
            path: 'payments/create',
            children: [
              {
                index: true,
                lazy: () => import('@/pages/billing/CreatePaymentView'),
              },
            ],
          },
          {
            path: 'payments',
            children: [
              {
                index: true,
                lazy: () => import('@/pages/billing/PaymentsView'),
              },
            ],
          },
          {
            index: true,
            lazy: () => import('@/pages/billing/AdministrationView'),
          },
        ],
      },
      {
        path: 'alerts',
        element: <AmaruGuard section="Sec:ConfiguracionAlertas" />,
        children: [
          {
            path: 'history',
            element: <AmaruGuard section="Sec:HistorialNotificacionesAlertas" />,
            children: [
              {
                index: true,
                lazy: () => import('@/pages/alerts/history'),
              },
            ],
          },
          {
            index: true,
            lazy: () => import('@/pages/alerts'),
          },
        ],
      },
      {
        path: 'sma',
        element: <AmaruGuard section="Sec:Sma" />,
        children: [
          {
            index: true,
            lazy: () => import('@/pages/sma'),
          },
        ],
      },
      // move following routes to an error boundary from react-router
      // https://reactrouter.com/en/main/route/error-element#throwing-responses
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
