import { useAuth } from '@/hooks';
import { useFormatters } from '@/hooks/useFormatters';
import { useDispatch } from '@/store';
import { useSuspenseQuery } from '@apollo/client';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Add, AddCircle, GppBad, GppGood, Launch, Phone } from '@mui/icons-material';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { Suspense, type FC } from 'react';
import GET_USER_PROFILE from '../graphql/queries/getUserProfile';
import { openUpdateUserProfileModal } from '../slices/userProfileSlice';
import UserProfileAuditLogs from './UserProfileAuditLogs';
import UserProfileAuditLogsSkeleton from './UserProfileAuditLogsSkeleton';
import UserProfileTemporaryUser from './UserProfileTemporaryUser';

interface Props {
  userId: number;
  onClose: () => void;
}

const UserProfile: FC<Props> = ({ userId, onClose }) => {
  const { _ } = useLingui();
  const { user: loggedUser } = useAuth();
  const { formatPhoneNumber, formatDateTime } = useFormatters();
  const dispatch = useDispatch();
  const { data } = useSuspenseQuery(GET_USER_PROFILE, {
    variables: {
      input: {
        id: userId,
      },
    },
  });

  const { user } = data;

  const onEditProfileClick = () => {
    if (!user?.id) return;
    dispatch(openUpdateUserProfileModal({ userId: user.id }));
  };

  const {
    mainOrganization,
    additionalOrganizations,
    phone,
    name,
    lastnames,
    fullName,
    username,
    twoFactorAuth,
  } = user ?? {};
  const initials = `${name?.[0] ?? ''}${lastnames?.[0] ?? ''}`;

  return (
    <Box>
      <Box display="flex" gap={4} alignItems="center" p={2}>
        {user?.picture ? (
          <Avatar
            sx={{ width: 96, height: 96 }}
            src={user.picture}
            alt={_(msg`Imagen de perfil`)}
          />
        ) : (
          <Avatar sx={{ width: 96, height: 96, fontSize: 40 }}>{initials}</Avatar>
        )}
        <Box>
          <Typography fontSize={24} fontWeight="bold">
            {fullName}
          </Typography>
          <Typography color="text.secondary">{username}</Typography>
          {loggedUser && loggedUser.id === user?.id && (
            <Button
              onClick={onEditProfileClick}
              variant="contained"
              color="info"
              sx={{ mt: 2 }}
              size="small"
              endIcon={<Launch />}
            >
              <Trans>Editar perfil</Trans>
            </Button>
          )}
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box p={2}>
          <Typography variant="h6">
            <Trans>Información general</Trans>
          </Typography>
          <List>
            <ListItem sx={{ py: 0.5 }}>
              <ListItemIcon>
                <Phone />
              </ListItemIcon>
              {phone ? (
                <ListItemText primary={formatPhoneNumber(phone)} />
              ) : (
                <ListItemText primary={_(msg`No hay teléfono registrado`)} />
              )}
            </ListItem>
            <ListItem sx={{ py: 0.5 }}>
              <ListItemIcon>
                <AddCircle />
              </ListItemIcon>
              <ListItemText primary={_(msg`Usuario creado el ${formatDateTime(user.created)}`)} />
            </ListItem>
            <ListItem sx={{ py: 0.5 }}>
              <ListItemIcon>{twoFactorAuth ? <GppGood /> : <GppBad />}</ListItemIcon>
              {twoFactorAuth ? (
                <ListItemText
                  primary={
                    <Trans>
                      Autenticación en dos pasos <b>activada</b>
                    </Trans>
                  }
                />
              ) : (
                <ListItemText
                  primary={
                    <Trans>
                      Autenticación en dos pasos <b>desactivada</b>
                    </Trans>
                  }
                />
              )}
            </ListItem>
            <UserProfileTemporaryUser userId={userId} />
          </List>
          <Typography variant="h6" mt={1}>
            <Trans>Organización</Trans>
          </Typography>
          <List>
            {mainOrganization && (
              <ListItem>
                <ListItemAvatar>
                  {mainOrganization?.organization?.photo ? (
                    <Avatar
                      src={mainOrganization.organization.photo}
                      alt={_(msg`Imagen de organización`)}
                    />
                  ) : (
                    <Avatar>{mainOrganization.organization?.name?.[0]}</Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={mainOrganization.organization?.name}
                  secondary={mainOrganization.role?.name}
                />
              </ListItem>
            )}
          </List>
          {Array.isArray(additionalOrganizations) && additionalOrganizations.length > 0 && (
            <>
              <Typography variant="h6" mt={1}>
                <Trans>Organizaciones adicionales</Trans>
              </Typography>
              <List>
                <ListItem>
                  <AvatarGroup
                    sx={{ justifyContent: 'start' }}
                    max={4}
                    renderSurplus={(surplus) => (
                      <Tooltip
                        title={
                          <List disablePadding>
                            {additionalOrganizations.slice(4).map((organization) => (
                              <ListItem
                                disablePadding
                                disableGutters
                                key={organization?.organization?.id + ' ' + organization?.role?.id}
                              >
                                <ListItemText
                                  primary={organization?.organization?.name}
                                  secondary={organization?.role?.name}
                                />
                              </ListItem>
                            ))}
                          </List>
                        }
                      >
                        <Avatar>+{surplus}</Avatar>
                      </Tooltip>
                    )}
                  >
                    {additionalOrganizations.map((organization) => (
                      <Tooltip
                        key={organization?.organization?.id + ' ' + organization?.role?.id}
                        title={
                          <List disablePadding>
                            <ListItem disablePadding disableGutters>
                              <ListItemText
                                primary={organization?.organization?.name}
                                secondary={organization?.role?.name}
                              />
                            </ListItem>
                          </List>
                        }
                        placement="top"
                      >
                        {organization?.organization?.photo ? (
                          <Avatar
                            src={organization.organization.photo}
                            alt={_(msg`Imagen de organización`)}
                          />
                        ) : (
                          <Avatar>{organization?.organization?.name?.[0]}</Avatar>
                        )}
                      </Tooltip>
                    ))}
                  </AvatarGroup>
                </ListItem>
              </List>
            </>
          )}
          {user?.id != null && (
            <Suspense fallback={<UserProfileAuditLogsSkeleton />}>
              <UserProfileAuditLogs userId={user.id} />
            </Suspense>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default UserProfile;
