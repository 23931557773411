import { gql } from '@/__generated__';
import { UseAuthorization_GetDeviceAuthorizationQuery } from '@/__generated__/graphql';

export type DeviceAuthorization =
  UseAuthorization_GetDeviceAuthorizationQuery['authorization']['authorizationDevice'];

const GET_DEVICE_AUTHORIZATION = gql(`#graphql
  query UseAuthorization_GetDeviceAuthorization($input: AuthorizationInput!) {
    authorization {
      authorizationDevice(input: $input) {
        deviceId
        update
        updateState
        updateAlarm
        updateAlias
        delete
        viewProfile
        viewStateHistorial
        viewUserAccess
        viewDeviceConfiguration
        clone
        viewCalibration
        viewFlowLimit
        viewFilter
        viewRatingCurve
        updateRatingCurve
        viewMathModeling
        viewRadarParameter
      }
    }
  }
`);

export default GET_DEVICE_AUTHORIZATION;
