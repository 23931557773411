import { Autocomplete, AutocompleteProps, ChipTypeMap, Skeleton, TextField } from '@mui/material';
import { useSuspenseAutocompleteOptions } from './hooks/useAutocompleteOptions';
import { useLingui } from '@lingui/react';
import { plural } from '@lingui/macro';
import { FC, Suspense } from 'react';

type DataType = 'devices' | 'organizations' | 'irrigations' | 'users' | 'projects' | 'currencies' | 'serviceTypes';
type Value<T extends DataType> = T extends 'devices'
  ? string
  : T extends 'organizations'
  ? number
  : T extends 'irrigations'
  ? number
  : T extends 'users'
  ? number
  : T extends 'projects'
  ? string
  : T extends 'currencies'
  ? number
  : T extends 'serviceTypes'
  ? number
  : never;

interface Props<
  TDataType extends DataType,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> extends Omit<
    AutocompleteProps<Value<TDataType>, Multiple, DisableClearable, false, ChipComponent>,
    'options' | 'renderInput'
  > {
  dataType: TDataType;
  label?: string;
  error?: boolean;
  helperText?: string;
  fallback?: React.ReactNode;
  queryOptions?: Parameters<typeof useSuspenseAutocompleteOptions>[1];
}

export const StandardAutocompleteIds = <
  TDataType extends DataType,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  ChipComponent extends React.ElementType,
>(
  props: Props<TDataType, Multiple, DisableClearable, ChipComponent>,
) => {
  const { _ } = useLingui();
  const { error, helperText, label, dataType, queryOptions, ...autocompleteProps } = props;
  const { data } = useSuspenseAutocompleteOptions(dataType, queryOptions);

  const getLabel = () => {
    if (label) return label;
    const count = props.multiple ? 2 : 1;
    if (dataType === 'devices')
      return _(
        plural(count, {
          one: 'Dispositivo',
          other: 'Dispositivos',
        }),
      );
    if (dataType === 'organizations')
      return _(
        plural(count, {
          one: 'Organización',
          other: 'Organizaciones',
        }),
      );
    if (dataType === 'irrigations')
      return _(
        plural(count, {
          one: 'Red de canales',
          other: 'Redes de canales',
        }),
      );
    if (dataType === 'users')
      return _(
        plural(count, {
          one: 'Usuario',
          other: 'Usuarios',
        }),
      );
    if (dataType === 'projects')
      return _(
        plural(count, {
          one: 'Proyecto',
          other: 'Proyectos',
        }),
      );
    if (dataType === 'currencies')
      return _(
        plural(count, {
          one: 'Moneda',
          other: 'Monedas',
        }),
      );
    if (dataType === 'serviceTypes')
      return _(
        plural(count, {
          one: 'Servicio',
          other: 'Servicios',
        }),
      );
    return '';
  };

  const getOptionLabel = (value: Value<TDataType>) => {
    if (!value) return '';
    if (dataType === 'devices') {
      const device = data.devices?.find((device) => device.id === value);
      if (!device) return String(value);
      return `${device.id} - ${device.profile.name}`;
    }
    if (dataType === 'organizations') {
      const organization = data.organizations?.find((organization) => organization.id === value);
      if (!organization) return String(value);
      return organization.name;
    }
    if (dataType === 'irrigations') {
      const irrigation = data.irrigations?.find((irrigation) => irrigation.id === value);
      if (!irrigation) return String(value);
      return `${irrigation.id} - ${irrigation.name}`;
    }
    if (dataType === 'users') {
      const user = data.users?.find((user) => user.id === value);
      if (!user) return String(value);
      return `${user.id} - ${user.name} ${user.lastnames}`;
    }
    if (dataType === 'projects') {
      const project = data.projects?.find((user) => user.id === value);
      if (!project) return String(project);
      return project.id;
    }
    if (dataType === 'currencies') {
      const currency = data.currencies?.find((user) => user.id === value);
      if (!currency) return String(currency);
      return currency.name;
    }
    if (dataType === 'serviceTypes') {
      const service = data.serviceTypes?.find((user) => user.id === value);
      if (!service) return String(service);
      return service.name;
    }
    return '';
  };

  return (
    <Autocomplete
      {...autocompleteProps}
      options={data[dataType]?.map((ent) => ent.id as Value<TDataType>) ?? []}
      getOptionLabel={autocompleteProps.getOptionLabel ?? getOptionLabel}
      renderInput={(params) => (
        <TextField {...params} label={label ?? getLabel()} error={error} helperText={helperText} />
      )}
    />
  );
};

export const StandardAutocompleteFallback: FC<Partial<AutocompleteProps<any, any, any, any>>> = (
  props,
) => {
  return (
    <Skeleton variant="rounded" width="100%" sx={{ ...props.sx }}>
      <Autocomplete
        {...props}
        options={[]}
        renderInput={(params) => <TextField {...params} label="" />}
      />
    </Skeleton>
  );
};

const StandardAutocompleteIdsWithFallback = <
  TDataType extends DataType,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>(
  props: Props<TDataType, Multiple, DisableClearable, ChipComponent>,
) => {
  return (
    <Suspense fallback={<StandardAutocompleteFallback />}>
      <StandardAutocompleteIds {...props} />
    </Suspense>
  );
};

export default StandardAutocompleteIdsWithFallback;
