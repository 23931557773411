import { t } from '@lingui/macro';

export const fields = [
  { id: 'organization', label: t`Cliente` },
  { id: 'id', label: t`ID` },
  { id: 'name', label: t`Nombre` },
  { id: 'type', label: t`Tipo` },
  { id: 'status', label: t`Estado` },
  { id: 'support_status', label: t`Estado soporte` },
  { id: 'etiquetas', label: t`Etiquetas` },
  { id: 'battery_soc', label: t`SOC mínimo de batería` },
  { id: 'battery_soc_slope', label: t`Carga diaria de batería` },
  { id: 'battery_soc_remain_days', label: t`Dias restantes de batería` },
  { id: 'last_metric_date', label: t`Último dato` },
  { id: 'ratio_buffer', label: t`Ratio Buffer` },
  { id: 'ratio_outlier', label: t`Ratio Outlier` },
  { id: 'type_battery', label: t`Tipo de energización` },
  { id: 'sim_mega', label: t`SIM Megas` },
  { id: 'operator_mobil', label: t`Operador` },
  { id: 'connectivity', label: t`Conectividad 24 Horas` },
  { id: 'events_count', label: t`Eventos` },
  { id: 'dga_active', label: t`DGA` },
];
