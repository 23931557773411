import { gql } from '@/__generated__';

const DEVICE_TOOLTIP_GET_USER_INFO = gql(`#graphql
  query DeviceTooltip_GetDeviceInfo(
    $deviceId: ID!
  ) {
    device(input: {
      id: $deviceId
    }) {
      id
      profile {
        id
        name
        organization {
          id
          name
        }
      }
    }
  }
`);

export default DEVICE_TOOLTIP_GET_USER_INFO;
