import { gql } from 'src/__generated__';

const FETCH_USERS = gql(`
  query accounts(
    $search: String
    $offset: Int!
    $organizationId: Int
    $limit: Int
    $orderBy: OrderByAccountInput
  ) {
    accounts(
      search: $search
      offset: $offset
      organizationId: $organizationId
      limit: $limit
      orderBy: $orderBy
    ) {
      count
      users {
        id
        email
        name
        lastnames
        token
        username
        phone
        picture
        created
        last_access
        verify_auth
        calling_code
        phone_number
        status
        organizations {
          id
          name
          verify_auth_orgs
          role {
            name
            id
          }
        }
      }
    }
  }
`);

export default FETCH_USERS;
