import { useModalStatusMessage } from '@/hooks';
import { setExtendTemporaryUserModal } from '@/slices/modals';
import { useDispatch, useSelector } from '@/store';
import { useMutation } from '@apollo/client';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Dialog, DialogContent, InputAdornment, Typography } from '@mui/material';
import { FormEventHandler, useState, type FC } from 'react';
import EXTEND_TEMPORARY_USER from '../../graphql/mutations/extendTemporaryUser';
import GET_USER_LIST from '../../graphql/queries/getUserList';
import NumericTextField from 'src/components/NumericTextField';

const ExtendTemporaryUserModal: FC = () => {
  const dispatch = useDispatch();
  const { _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { open, userId } = useSelector((state) => state.modals_store.extendTemporaryUserModal);
  const [days, setDays] = useState(7);
  const [extend] = useMutation(EXTEND_TEMPORARY_USER, {
    onCompleted() {
      openModalSuccessMessage(_(msg`Se ha extendido el tiempo de expiración del usuario`));
      onClose();
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
    refetchQueries: [GET_USER_LIST],
    update(cache) {
      cache.modify({
        id: cache.identify({
          __typename: 'User2',
          id: userId,
        }),
        fields: {
          temporaryUser(_, { INVALIDATE }) {
            return INVALIDATE;
          },
        },
      });
    },
  });

  if (!userId) return null;

  const onClose = () => {
    dispatch(setExtendTemporaryUserModal({ open: false }));
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    extend({
      variables: {
        input: {
          userId,
          days,
        },
      },
    });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <form onSubmit={onSubmit}>
          <ModalTitle title={_(msg`Extender duración del usuario temporal`)} />
          <DialogContent dividers>
            <Typography mb={2}>
              <Trans>¿Por cuánto tiempo deseas extender la duración del usuario?</Trans>
            </Typography>
            <NumericTextField
              value={days}
              onChange={(event) => setDays(Number(event.target.value))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Trans>días</Trans>
                  </InputAdornment>
                ),
              }}
            />{' '}
          </DialogContent>
          <ModalActions
            onClose={onClose}
            closeButton={{ label: _(msg`Cancelar`) }}
            onSubmit="submit"
            submitLabel={_(msg`Extender`)}
          />
        </form>
      </Dialog>
    </>
  );
};

export default ExtendTemporaryUserModal;
