import { Authorization } from '../graphql/queries/getAuthorization';
import { DeviceAuthorization } from '../graphql/queries/getDeviceAuthorization';
import { Access } from './accessEnum';

function validateAccess(
  authorization: Authorization,
  authorizationDevice: DeviceAuthorization | undefined,
  access: Access | null,
  deviceId?: string | null,
) {
  // Device authorization statements
  if (deviceId != null && authorizationDevice?.deviceId === deviceId) {
    if (access === Access.DeviceConfigurationModal && authorizationDevice.update) {
      return true;
    }
    if (
      access === Access.DeviceDataConfigurationModal &&
      authorizationDevice.viewDeviceConfiguration
    ) {
      return true;
    }
    if (access === Access.DeleteDeviceModal && authorizationDevice.delete) {
      return true;
    }
    if (access === Access.CloneDeviceModal && authorizationDevice.clone) {
      return true;
    }
  }

  if (
    access === Access.DeviceAlertsConfigurationModal &&
    authorization.authorizationAlert?.adminView
  ) {
    return true;
  }

  if (access === Access.CreateDGAConfigurationModal) {
    if (authorization.authorizationDGA?.enableDevices) {
      return true;
    }
  }
  if (access === Access.AlertsConfiguration_TableRowAdminView) {
    if (authorization.authorizationAlert?.adminView) {
      return true;
    }
  }

  if (access === Access.UsersTable_OptionsMenu) {
    if (
      authorization.authorizationGeneral?.updateUser ||
      authorization.authorizationGeneral?.simulateUser
    ) {
      return true;
    }
  }

  if (
    access === Access.UserOptionsMenu_DeleteUserButton ||
    access === Access.SaveUserModal ||
    access === Access.DeleteUserModal ||
    access === Access.CreateTemporalUserModal
  ) {
    if (authorization.authorizationGeneral?.updateUser) {
      return true;
    }
  }

  if (access === Access.UserOptionsMenu_SimulateUserButton || access === Access.SimulateUserModal) {
    if (authorization.authorizationGeneral?.simulateUser) {
      return true;
    }
  }

  if (access === Access.OrganizationsFilter) {
    if (authorization.authorizationGeneral?.showOrganizationFilter) {
      return true;
    }
  }

  if (access === Access._Test_True) return true;
  if (access === Access._Test_False) return false;
  if (access === Access._Test_Random) return Math.random() > 0.5;

  return false;
}

export { validateAccess };
