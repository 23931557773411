import { InputLabel, Select, SelectProps } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { FormControl, MenuItem } from '@mui/material';

type Props<T extends FieldValues, U extends number | string | object> = SelectProps & {
  name: Path<T>;
  control: Control<T>;
  options: {
    value: U;
    label: string;
  }[];
  rules?: any;
  fullWidth?: boolean;
};

const FormSelect = <T extends FieldValues, U extends number | string | object>(
  props: Props<T, U>,
) => {
  const { fullWidth, name, label, control, options, rules, ...rest } = props;
  const labelId = name + '-label';
  return (
    <FormControl fullWidth={fullWidth ?? true}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          // TODO: Select MUI component can be a little bit buggy (mostly weird rendering behaviour). Maybe change to Autocomplete!
          <Select {...rest} labelId={labelId} label={label} onChange={onChange} value={value}>
            {options.map((o) => (
              <MenuItem id={`${o.value}`} key={o.value.toString()} value={o.value as any}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export { FormSelect };
