import { gql } from '@/__generated__';

const EXTEND_TEMPORARY_USER = gql(`#graphql
  mutation ExtendTemporaryUser($input: ExtendTemporaryUserInput!) {
    extendTemporaryUser(input: $input) {
      id
      temporaryUser {
        activationToken
        activationTokenExpiration
        temporaryUserDurationDays
        temporaryUserExpiration
      }
    }
  }
`);

export default EXTEND_TEMPORARY_USER;
