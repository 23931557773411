import { useQuery, useSuspenseQuery } from '@apollo/client';
import STANDARD_AUTOCOMPLETE_DATA from '../queries/getStandardAutocompleteData';
import { DevicesOrderBy } from '@/__generated__/graphql';

export const useAutocompleteOptions = (
  dataType: 'devices' | 'irrigations' | 'organizations' | 'users' | 'projects' | 'currencies' | 'billingServices' | 'serviceTypes',
  queryOptions?: {
    includeGates?: boolean;
    withDgaDevices?: boolean;
    projectId?: string,
  },
) => {
  return useQuery(STANDARD_AUTOCOMPLETE_DATA, {
    variables: {
      includeOrganizations: dataType === 'organizations',
      includeDevices: dataType === 'devices',
      devicesInput: {
        orderBy: DevicesOrderBy.IdAsc,
        includeGates: queryOptions?.includeGates,
        project: queryOptions?.projectId
      },
      organizationsInput: {
        withDgaDevices: queryOptions?.withDgaDevices,
      },
      includeIrrigations: dataType === 'irrigations',
      includeUsers: dataType === 'users',
      includeProjects: dataType === 'projects',
      includeCurrencies: dataType === 'currencies',
      includeServiceTypes: dataType === 'serviceTypes',
    },
  });
};

export const useSuspenseAutocompleteOptions = (
  dataType: 'devices' | 'irrigations' | 'organizations' | 'users' | 'projects' | 'currencies' | 'serviceTypes',
  queryOptions?: {
    includeGates?: boolean;
    withDgaDevices?: boolean;
    users?: {
      fromUserOrganizations?: boolean;
    };
    projectId?: string,
    projects?: {
      organizationId: number
    }
  },
) => {
  return useSuspenseQuery(STANDARD_AUTOCOMPLETE_DATA, {
    variables: {
      devicesInput: {
        orderBy: DevicesOrderBy.IdAsc,
        includeGates: queryOptions?.includeGates,
        project: queryOptions?.projectId
      },
      organizationsInput: {
        withDgaDevices: queryOptions?.withDgaDevices,
      },
      usersInput: {
        fromUserOrganizations: queryOptions?.users?.fromUserOrganizations,
      },
      projectsInput: {
        organizationId: queryOptions?.projects?.organizationId
      },
      includeOrganizations: dataType === 'organizations',
      includeDevices: dataType === 'devices',
      includeIrrigations: dataType === 'irrigations',
      includeUsers: dataType === 'users',
      includeProjects: dataType === 'projects',
      includeCurrencies: dataType === 'currencies',
      includeServiceTypes: dataType === 'serviceTypes',
    },
  });
};
