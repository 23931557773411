import useIsRegis from '@/hooks/useIsRegis';
import {
  setDeprecatedCreateMeasurementModal,
  setDeprecatedMeasurementsTableModal,
  setUpdateDeviceAlertsModal,
  setUpdateDeviceDataConfigurationModal,
} from '@/slices/modals';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import { AuthorizationWrapper, Access } from '@features/authorization';
import { setUpdateDeviceConfigurationModal } from '@features/deviceConfigurationModal/slices/deviceConfigurationSlice';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Settings } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Skeleton, Tooltip } from '@mui/material';
import { PropsWithChildren, Suspense, useState, ComponentType, type FC } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  deviceId: string;
  CustomIcon?: ComponentType;
}

interface WrapperProps extends PropsWithChildren<Props> {}

interface MenuButton {
  id: number;
  access: Access | null;
  label: string;
  onClick: () => void;
  Wrapper?: FC<WrapperProps>;
}

const IsRegisWrapper: FC<WrapperProps> = ({ children, deviceId }) => {
  const isRegis = useIsRegis(deviceId);
  return isRegis ? children : null;
};

const MenuItems: FC<Props> = ({ deviceId }) => {
  const dispatch = useDispatch();
  const { _ } = useLingui();

  const buttons: MenuButton[] = [
    {
      id: 1,
      access: Access.DeviceConfigurationModal,
      label: _(msg`Configuración de dispositivo`),
      onClick: () => {
        dispatch(
          setUpdateDeviceConfigurationModal({
            open: true,
            deviceId,
          }),
        );
      },
    },
    {
      id: 2,
      access: Access.DeviceDataConfigurationModal,
      label: _(msg`Configuración de datos`),
      onClick: () => {
        dispatch(
          setUpdateDeviceDataConfigurationModal({
            open: true,
            deviceId,
          }),
        );
      },
    },
    {
      id: 3,
      access: Access.DeviceAlertsConfigurationModal,
      label: _(msg`Configuración de alertas`),
      onClick: () => {
        dispatch(
          setUpdateDeviceAlertsModal({
            open: true,
            deviceId,
          }),
        );
      },
    },
    {
      id: 4,
      access: null,
      label: _(msg`Ingreso manual de datos`),
      onClick: () => {
        dispatch(
          setDeprecatedCreateMeasurementModal({
            open: true,
            deviceId,
          }),
        );
      },
      Wrapper: IsRegisWrapper,
    },
    {
      id: 5,
      access: null,
      label: _(msg`Ver tabla de datos`),
      onClick: () => {
        dispatch(
          setDeprecatedMeasurementsTableModal({
            open: true,
            deviceId,
          }),
        );
      },
      Wrapper: IsRegisWrapper,
    },
  ];

  return buttons.map((button) => {
    const Wrapper = button.Wrapper ?? (({ children }) => children);
    return (
      <ApolloErrorBoundary key={button.id}>
        <Suspense
          fallback={
            <Skeleton>
              <MenuItem>{button.label}</MenuItem>
            </Skeleton>
          }
        >
          <AuthorizationWrapper access={button.access} fallback={null} deviceId={deviceId}>
            <Wrapper deviceId={deviceId}>
              <MenuItem key={button.label} onClick={button.onClick}>
                {button.label}
              </MenuItem>
            </Wrapper>
          </AuthorizationWrapper>
        </Suspense>
      </ApolloErrorBoundary>
    );
  });
};

const DeviceMenu: FC<Props> = ({ deviceId, CustomIcon }) => {
  const { _ } = useLingui();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {CustomIcon ? (
        <Tooltip title={_(msg`Opciones de dispositivo`)}>
          <IconButton onClick={handleClick}>
            <CustomIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Settings fontSize="large" />
        </IconButton>
      )}

      <Menu
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItems deviceId={deviceId} />
      </Menu>
    </div>
  );
};

export default DeviceMenu;
