import { ActionType } from '@/__generated__/graphql';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

export const getDisplayNameActionType = (actionType: ActionType, i18n: I18n) => {
  const actionTypeDict: { [id in ActionType]: string } = {
    [ActionType.UpdateCalibration]: t(i18n)`actualizó la calibración`,
    [ActionType.UpdateFlowLimits]: t(i18n)`actualizó los límites de caudal`,
    [ActionType.UpdateDeviceFilters]: t(i18n)`actualizó los filtros del dispositivo`,
    [ActionType.UpdateRadarParameters]: t(i18n)`actualizó los parámetros del radar`,
    [ActionType.UpdateRatingCurves]: t(i18n)`actualizó las curvas de aforo`,
    [ActionType.UpdateMathModeling]: t(i18n)`actualizó el modelado matemático`,
    [ActionType.UpdateInfo]: t(i18n)`actualizó la información`,
    [ActionType.UpdateDeviceStatus]: t(i18n)`actualizó el estado del dispositivo`,
    [ActionType.DeleteDevice]: t(i18n)`eliminó el dispositivo`,
    [ActionType.CreateDevice]: t(i18n)`creó el dispositivo`,
    [ActionType.UpdateDeviceAccess]: t(i18n)`actualizó el acceso al dispositivo`,
    [ActionType.UpdateDeviceAlerts]: t(i18n)`actualizó las alertas del dispositivo`,
    [ActionType.DisableDeviceAlerts]: t(i18n)`desactivó las alertas del dispositivo`,
    [ActionType.UpdateDeviceAlarms]: t(i18n)`actualizó las alarmas del dispositivo`,
    [ActionType.UpdateDeviceAlertNotifications]: t(i18n)`actualizó las notificaciones de alerta del dispositivo`,
    [ActionType.UpdateDeviceCalendarConfiguration]: t(
      i18n,
    )`actualizó la configuración del calendario del dispositivo`,
    [ActionType.DeleteDeviceCalendarConfiguration]: t(
      i18n,
    )`eliminó la configuración del calendario del dispositivo`,
    [ActionType.CreateDeviceCalendarConfiguration]: t(
      i18n,
    )`creó la configuración del calendario del dispositivo`,
    [ActionType.CreateDeviceHardwareConfiguration]: t(
      i18n,
    )`creó la configuración del hardware del dispositivo`,
    [ActionType.UpdateDeviceHardwareConfiguration]: t(
      i18n,
    )`actualizó la configuración del hardware del dispositivo`,
    [ActionType.DeleteDeviceHardwareConfiguration]: t(
      i18n,
    )`eliminó la configuración del hardware del dispositivo`,
    [ActionType.SendConfigCommandToDevice]: t(i18n)`envió un comando al dispositivo`,
    [ActionType.UpdateStatusSupport]: t(i18n)`actualizó el estado de soporte del dispositivo`,
    [ActionType.CreateDeviceAliases]: t(i18n)`creó el alias del dispositivo`,
    [ActionType.UpdateDeviceAliases]: t(i18n)`actualizó el alias del dispositivo`,
    [ActionType.DeleteDeviceAliases]: t(i18n)`eliminó el alias del dispositivo`,
    [ActionType.CloneDevice]: t(i18n)`clonó el dispositivo`,
    // User
    [ActionType.CreateUser]: t(i18n)`creó el usuario`,
    [ActionType.UpdateUser]: t(i18n)`actualizó el usuario`,
    [ActionType.DeleteUser]: t(i18n)`eliminó el usuario`,
    [ActionType.UpdateUserAccess]: t(i18n)`actualizó el acceso del usuario`,
    [ActionType.UpdateUserAlerts]: t(i18n)`actualizó las alertas del usuario`,
    [ActionType.UpdateUserAlarms]: t(i18n)`actualizó las alarmas del usuario`,
    [ActionType.UpdateUserSupportFilters]: t(
      i18n,
    )`actualizó los filtros de configuración de soporte del usuario`,
    [ActionType.UpdateUserSupportFields]: t(
      i18n,
    )`actualizó los campos de configuración de soporte del usuario`,
    [ActionType.ImpersonateUser]: t(i18n)`suplantó al usuario`,
    [ActionType.UpdateUserPreferences]: t(i18n)`actualizó las preferencias del usuario`,
    // Irrigation
    [ActionType.CreateIrrigation]: t(i18n)`creó la irrigación`,
    [ActionType.DeleteIrrigation]: t(i18n)`eliminó la irrigación`,
    [ActionType.UpdateIrrigation]: t(i18n)`actualizó la irrigación`,
    // Sira Distribution
    [ActionType.UpdateSiraDistribution]: t(i18n)`actualizó la distribución de SIRA`,
    [ActionType.CreateNewSiraDistribution]: t(i18n)`creó una nueva distribución de SIRA`,
    // Sira Canal
    [ActionType.CreateSiraCanalClass]: t(i18n)`creó la clase de canal de SIRA`,
    [ActionType.UpdateSiraCanalClass]: t(i18n)`actualizó la clase de canal de SIRA`,
    [ActionType.DeleteSiraCanalClass]: t(i18n)`eliminó la clase de canal de SIRA`,
    [ActionType.CreateSiraUserToNotify]: t(i18n)`creó el usuario de SIRA para notificar`,
    [ActionType.UpdateSiraUserToNotify]: t(i18n)`actualizó el usuario de SIRA para notificar`,
    [ActionType.DeleteSiraUserToNotify]: t(i18n)`eliminó el usuario de SIRA para notificar`,
    // Organization
    [ActionType.CreateOrganization]: t(i18n)`creó la organización`,
    [ActionType.DeleteOrganization]: t(i18n)`eliminó la organización`,
    [ActionType.UpdateOrganization]: t(i18n)`actualizó la organización`,
    [ActionType.UpdateOrganizationAccess]: t(i18n)`actualizó el acceso a la organización`,
    [ActionType.UpdateOrganizationSetting]: t(i18n)`actualizó la configuración de la organización`,
    [ActionType.CreateApiForOrganization]: t(i18n)`creó la API para la organización`,
    [ActionType.DeleteApiForOrganization]: t(i18n)`eliminó la API para la organización`,
    [ActionType.UpdateApiForOrganization]: t(i18n)`actualizó la API para la organización`,
    [ActionType.UpdateApiPasswordForOrganization]: t(
      i18n,
    )`actualizó la contraseña de la API para la organización`,
    [ActionType.CreateSiraMaipoConfig]: t(i18n)`creó la configuración de SIRA Maipo`,
    [ActionType.CreateSiraMaipoConfigDevice]: t(
      i18n,
    )`creó la configuración del dispositivo para SIRA Maipo`,
    [ActionType.CreateSiraMaipoDevOrgConfig]: t(
      i18n,
    )`creó la configuración del dispositivo u organización para SIRA Maipo`,
    [ActionType.CreateSiraMaipoConfigOrg]: t(
      i18n,
    )`creó la configuración de la organización para SIRA Maipo`,
    [ActionType.CreateNewSiraMaipoDistribution]: t(
      i18n,
    )`creó una nueva distribución para SIRA Maipo`,
    [ActionType.DeleteSiraMaipoDistribution]: t(i18n)`eliminó la distribución de SIRA Maipo`,
    [ActionType.UpdateSiraMaipoDistribution]: t(i18n)`actualizó la distribución de SIRA Maipo`,
    [ActionType.UpdateSiraMaipoDistributionShares]: t(
      i18n,
    )`actualizó las acciones de la distribución de SIRA Maipo`,
    [ActionType.UpdateSiraMaipoDistributionMinDotation]: t(
      i18n,
    )`actualizó la dotación mínima de la distribución de SIRA Maipo`,
    // Gates
    [ActionType.SendMovementCommandToGate]: t(i18n)`envió un comando de movimiento a la compuerta`,
    [ActionType.SendStopMovementCommandToGate]: t(
      i18n,
    )`envió un comando de detención de movimiento a la compuerta`,
    [ActionType.DeleteGateEnqueueMoveRequest]: t(
      i18n,
    )`eliminó la solicitud de movimiento en cola de la compuerta`,
    // Reports
    [ActionType.CreateReport]: t(i18n)`creó el reporte`,
    [ActionType.DeleteReport]: t(i18n)`eliminó el reporte`,
    [ActionType.UpdateReport]: t(i18n)`actualizó el reporte`,
    [ActionType.CreateReportSchedule]: t(i18n)`creó el cronograma del reporte`,
    [ActionType.DeleteReportSchedule]: t(i18n)`eliminó el cronograma del reporte`,
    [ActionType.UpdateReportSchedule]: t(i18n)`actualizó el cronograma del reporte`,
    // Project renewals
    [ActionType.UpdateProjectRenewalDueDate]: t(
      i18n,
    )`actualizó la fecha de vencimiento del cobro recurrente del proyecto`,
    [ActionType.UpdateProjectRenewalPeriodicity]: t(
      i18n,
    )`actualizó la periodicidad del cobro recurrente del proyecto`,
    [ActionType.CreateProjectRenewalPayment]: t(
      i18n,
    )`creó un nuevo pago para un proyecto.`,
    [ActionType.UpdateProjectRenewalPayment]: t(
      i18n,
    )`actualizó la información del pago de un proyecto.`,
    [ActionType.DeleteProjectRenewalPayment]: t(
      i18n,
    )`eliminó un pago del proyecto.`,
    [ActionType.CreateProjectRenewalService]: t(
      i18n,
    )`creó un nuevo servicio para el proyecto.`,
    [ActionType.UpdateProjectRenewalService]: t(
      i18n,
    )`actualizó un servicio del proyecto.`,
    [ActionType.DeleteProjectRenewalService]: t(
      i18n,
    )`eliminó un servicio del proyecto.`,
    // dga
    [ActionType.UpdateDgaInfo]: t(i18n)`actualizó la información de DGA`, // old
    [ActionType.CreateDgaConfiguration]: t(i18n)`creó la configuración de DGA`,
    [ActionType.DeleteDgaConfiguration]: t(i18n)`eliminó la configuración de DGA`,
    [ActionType.UpdateDgaConfiguration]: t(i18n)`actualizó la configuración de DGA`,
    [ActionType.DeleteDgaSchedule]: t(
      i18n,
    )`eliminó la configuración de la recurrencia de envío de DGA`,
    [ActionType.EnableDgaSchedule]: t(i18n)`habilitó el envío de DGA`,
    [ActionType.DisableDgaSchedule]: t(i18n)`deshabilitó el envío de DGA`,
    [ActionType.UpdateDgaSchedule]: t(
      i18n,
    )`actualizó la configuración de recurrencia de envío de DGA`,
    [ActionType.UpdateDgaNotificationConfiguration]: t(
      i18n,
    )`actualizó la configuración de notificación de DGA`,
  };

  return Object.prototype.hasOwnProperty.call(actionTypeDict, actionType)
    ? actionTypeDict[actionType]
    : actionType;
};
