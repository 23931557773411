import { useModalStatusMessage } from '@/hooks';
import { useFormatters } from '@/hooks/useFormatters';
import { generateTemporaryUserActivationURL } from '@/utils/generateTemporaryUserActivationURL';
import { useSuspenseQuery } from '@apollo/client';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { AccessTime, CopyAll, MoreTime } from '@mui/icons-material';
import { Button, Divider, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ReactNode, type FC } from 'react';
import { setExtendTemporaryUserModal, setExtendTemporaryUserTokenModal } from 'src/slices/modals';
import GET_USER_PROFILE from '../graphql/queries/getUserProfile';
import { useDispatch } from 'src/store';
import { Box } from '@mui/system';
import { setUseStrictShallowCopy } from 'immer';

interface Props {
  userId: number;
}

enum TemporaryUserState {
  NotTemporary,
  InactiveWithValidToken,
  InactiveWithInvalidToken,
  ActiveEnabled,
  ActiveExpirated,
}

const UserProfileTemporaryUser: FC<Props> = ({ userId }) => {
  const { _ } = useLingui();
  const { formatRelativeTime } = useFormatters();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const dispatch = useDispatch();
  const { data } = useSuspenseQuery(GET_USER_PROFILE, {
    variables: {
      input: {
        id: userId,
      },
    },
  });
  const { user } = data;

  let state = TemporaryUserState.NotTemporary;
  if (user.temporaryUser) {
    const { activationToken, activationTokenExpiration, temporaryUserExpiration } =
      user.temporaryUser;
    if (activationToken && !temporaryUserExpiration) {
      if (activationTokenExpiration && activationTokenExpiration > Date.now()) {
        state = TemporaryUserState.InactiveWithValidToken;
      } else if (activationTokenExpiration && activationTokenExpiration <= Date.now()) {
        state = TemporaryUserState.InactiveWithInvalidToken;
      }
    }

    if (temporaryUserExpiration) {
      if (temporaryUserExpiration > Date.now()) {
        state = TemporaryUserState.ActiveEnabled;
      } else {
        state = TemporaryUserState.ActiveExpirated;
      }
    }
  }

  if (state === TemporaryUserState.NotTemporary) return null;

  const attributes = {
    label: '' as ReactNode,
    showCopyButton: false,
    copyButtonAction: () => {},
    copyButtonLabel: '' as ReactNode,
    buttonLabel: '' as ReactNode,
    buttonAction: () => {},
    buttonIcon: <MoreTime />,
  };

  if (state === TemporaryUserState.ActiveExpirated) {
    attributes.label = (
      <Trans>
        Usuario temporal <b>expirado</b>
      </Trans>
    );
    attributes.buttonLabel = (
      <Trans>
        Extender duración de usuario (expiró{' '}
        {formatRelativeTime(user.temporaryUser?.temporaryUserExpiration ?? 0)})
      </Trans>
    );
    attributes.buttonAction = () =>
      dispatch(setExtendTemporaryUserModal({ open: true, userId: user.id }));
  }

  if (state === TemporaryUserState.ActiveEnabled) {
    attributes.label = (
      <Trans>
        Usuario temporal <b>activo</b>{' '}
      </Trans>
    );
    attributes.buttonLabel = (
      <Trans>
        Extender duración de usuario (expira{' '}
        {formatRelativeTime(user.temporaryUser?.temporaryUserExpiration ?? 0)})
      </Trans>
    );
    attributes.buttonAction = () =>
      dispatch(setExtendTemporaryUserModal({ open: true, userId: user.id }));
  }

  if (state === TemporaryUserState.InactiveWithInvalidToken) {
    attributes.label = (
      <Trans>
        Usuario temporal <b>sin activar</b>
      </Trans>
    );
    attributes.buttonLabel = (
      <Trans>
        Extender duración del token (expiró{' '}
        {formatRelativeTime(user.temporaryUser?.activationTokenExpiration ?? 0)})
      </Trans>
    );
    attributes.buttonAction = () =>
      dispatch(setExtendTemporaryUserTokenModal({ open: true, userId: user.id }));
  }

  if (state === TemporaryUserState.InactiveWithValidToken) {
    attributes.label = (
      <Trans>
        Usuario temporal <b>sin activar</b>
      </Trans>
    );
    attributes.copyButtonLabel = <Trans>Copiar link de activación</Trans>;
    attributes.buttonAction = () =>
      dispatch(setExtendTemporaryUserTokenModal({ open: true, userId: user.id }));
    attributes.buttonLabel = (
      <Trans>
        Extender duración del token (expira{' '}
        {formatRelativeTime(user.temporaryUser?.activationTokenExpiration ?? 0)})
      </Trans>
    );
    attributes.showCopyButton = true;
    attributes.copyButtonAction = async () => {
      if (!user.temporaryUser?.activationToken) return;
      const token = user.temporaryUser.activationToken;
      const url = generateTemporaryUserActivationURL(token);
      try {
        await navigator.clipboard.writeText(url);
        openModalSuccessMessage(_(msg`URL copiada correctamente`));
      } catch (error) {
        if (error instanceof Error) {
          openModalErrorMessage(error.message);
        }
      }
    };
  }

  return (
    <ListItem sx={{ py: 0.5 }}>
      <ListItemIcon>
        <AccessTime />
      </ListItemIcon>
      <ListItemText
        primary={attributes.label}
        secondary={
          <Box>
            {attributes.showCopyButton && (
              <Button startIcon={<CopyAll />} onClick={attributes.copyButtonAction}>
                {attributes.copyButtonLabel}
              </Button>
            )}
            <Button startIcon={attributes.buttonIcon} onClick={attributes.buttonAction}>
              {attributes.buttonLabel}
            </Button>
          </Box>
        }
      />
    </ListItem>
  );
};

export default UserProfileTemporaryUser;
