import { Typography } from '@mui/material';
import { type FC } from 'react';

const SectionTitle: FC<{ title: string; id: string }> = ({ title, id }) => {
  return (
    <Typography id={id} variant="h2" sx={{ fontSize: '20px !important' }}>
      {title}
    </Typography>
  );
};

export default SectionTitle;
