import GET_USER_LIST from '@/graphql/querys/getUserList';
import { setSaveUserModal } from '@/slices/modals';
import { useDispatch, useSelector } from '@/store';
import { useQuery } from '@apollo/client';
import { type FC } from 'react';
import UserSaveModal from '..';

const SaveUserModalGlobal: FC = () => {
  const dispatch = useDispatch();
  const { saveUserModal } = useSelector((state) => state.modals_store);
  const { data } = useQuery(GET_USER_LIST, {
    variables: {
      input: {
        ids: saveUserModal.userId != null ? [saveUserModal.userId] : null,
      },
    },
    skip: !saveUserModal.open || !saveUserModal.userId,
  });

  const onClose = () => {
    dispatch(
      setSaveUserModal({
        open: false,
      }),
    );
  };

  if (saveUserModal.userId == null)
    return <UserSaveModal open={saveUserModal.open} onClose={onClose} userToSave={null} />;

  if (!data?.usersPagination.items[0]) return null;

  return (
    <UserSaveModal
      open={saveUserModal.open}
      onClose={onClose}
      userToSave={data?.usersPagination?.items[0]}
    />
  );
};

export default SaveUserModalGlobal;
