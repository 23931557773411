import { gql } from 'src/__generated__';

const UPDATE_ALERT_CONFIG = gql(`#graphql 
mutation UpdateAlertConfig($input: AlertConfigInput!) {
  updateAlertConfig(input: $input) {
    metric
    ok {
      unit
      comparisonType
      eventName
      thresholdValue
      thresholdMax
      thresholdMin
      notificationDelay
    }
    info {
      unit
      comparisonType
      eventName
      thresholdValue
      thresholdMax
      thresholdMin
      notificationDelay
    }
    warning {
      unit
      comparisonType
      eventName
      thresholdValue
      thresholdMax
      thresholdMin
      notificationDelay
    }
    critical {
      unit
      comparisonType
      eventName
      thresholdValue
      thresholdMax
      thresholdMin
      notificationDelay
    }
  }
}`);

export { UPDATE_ALERT_CONFIG };
