import { gql } from '@/__generated__';

const EXTEND_TEMPORARY_USER_TOKEN = gql(`#graphql
  mutation ExtendTemporaryUserToken($input: ExtendTemporaryUserTokenInput!) {
    extendTemporaryUserToken(input: $input) {
      id
      temporaryUser {
        activationToken
        activationTokenExpiration
        temporaryUserDurationDays
        temporaryUserExpiration
      }
    }
  }
`);

export default EXTEND_TEMPORARY_USER_TOKEN;
