import { t } from '@lingui/macro';

export const statusIds = ['active', 'testing', 'maintenance'];

export const statusDict = {
  active: t`Activos`,
  testing: t`En prueba`,
  maintenance: t`En Mantenimiento`,
};

export const supportStatus = [
  {
    value: 'all',
    label: t`Todos`,
  },
  {
    value: 'operativo',
    label: t`Operativo`,
  },
  {
    value: 'responsable_cliente',
    label: t`Responsable cliente`,
  },
  {
    value: 'pendiente',
    label: t`Pendiente`,
  },
  {
    value: 'critico',
    label: t`Crítico`,
  },
  {
    value: 'apagado',
    label: t`Apagado`,
  },
];

export const supportStatusIds = [
  'operativo',
  'responsable_cliente',
  'critico',
  'pendiente',
  'apagado',
];

export const supportStatusDict = {
  operativo: { name: t`Operativo`, color: 'green' },
  responsable_cliente: { name: t`Responsable cliente`, color: '#87CEEB' },
  critico: { name: t`Crítico`, color: 'orange' },
  pendiente: { name: t`Pendiente`, color: 'yellow' },
  apagado: { name: t`Apagado`, color: 'red' },
};
