import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SmaDeviceMetric } from 'src/__generated__/graphql';

type ModalRequiredDevice =
  | {
      open: true;
      deviceId: string;
    }
  | {
      open: false;
    };

interface ModalsState {
  updateDeviceAlertsModal: {
    open: boolean;
    deviceId?: string;
  };
  createDeviceObservationModal: ModalRequiredDevice;
  updateDeviceObservationModal:
    | {
        open: true;
        observationId: number;
      }
    | {
        open: false;
      };
  deleteDeviceObservationModal:
    | {
        open: true;
        observationId: number;
      }
    | {
        open: false;
      };
  updateDeviceDataConfigurationModal: ModalRequiredDevice;
  /** @deprecated */
  deprecatedCreateMeasurementModal: ModalRequiredDevice;
  /** @deprecated */
  deprecatedMeasurementsTableModal: ModalRequiredDevice;
  upsertSmaConfigurationModal:
    | {
        open: true;
        mode: 'update' | 'create';
        initialSmaConfig?: SmaDeviceMetric;
        initialDeviceId?: string;
      }
    | {
        open: false;
        mode?: 'update' | 'create';
        initialSmaConfig?: SmaDeviceMetric;
        initialDeviceId?: string;
      };
  smaSuccessTableModal:
    | {
        open: false;
      }
    | {
        open: true;
        deviceId: string;
        smaUnitId: string;
        smaDeviceId: string;
        smaProcessId: string;
        smaMetricName: string;
      };
  upsertAlertAdvancedCreationModal:
    | {
        open: true;
        mode: 'update' | 'create';
        initialDeviceId?: string;
      }
    | {
        open: false;
        mode?: 'update' | 'create';
        initialDeviceId?: string;
      };
  saveUserModal: {
    open: boolean;
    userId?: number;
  };
  simulateUserModal:
    | {
        open: true;
        userId: number;
      }
    | {
        open: false;
      };
  deleteUserModal:
    | {
        open: true;
        userId: number;
      }
    | {
        open: false;
      };
  userProfileModal:
    | {
        open: true;
        userId: number;
      }
    | {
        open: false;
      };
  createTemporaryUserModal: {
    open: boolean;
  };
  extendTemporaryUserModal: {
    open: boolean;
    userId?: number;
  };
  extendTemporaryUserTokenModal: {
    open: boolean;
    userId?: number;
  };
}

const initialState: ModalsState = {
  updateDeviceAlertsModal: {
    open: false,
  },
  createDeviceObservationModal: {
    open: false,
  },
  updateDeviceObservationModal: {
    open: false,
  },
  deleteDeviceObservationModal: {
    open: false,
  },
  updateDeviceDataConfigurationModal: {
    open: false,
  },
  deprecatedCreateMeasurementModal: {
    open: false,
  },
  deprecatedMeasurementsTableModal: {
    open: false,
  },
  upsertSmaConfigurationModal: {
    open: false,
  },
  smaSuccessTableModal: {
    open: false,
  },
  upsertAlertAdvancedCreationModal: {
    open: false,
  },
  saveUserModal: {
    open: false,
  },
  simulateUserModal: {
    open: false,
  },
  deleteUserModal: {
    open: false,
  },
  userProfileModal: {
    open: false,
  },
  createTemporaryUserModal: {
    open: false,
  },
  extendTemporaryUserModal: {
    open: false,
  },
  extendTemporaryUserTokenModal: {
    open: false,
  },
};

const { reducer, actions } = createSlice({
  name: 'modals_store',
  initialState,
  reducers: {
    setUpdateDeviceAlertsModal(
      state,
      action: PayloadAction<ModalsState['updateDeviceAlertsModal']>,
    ) {
      state.updateDeviceAlertsModal = action.payload;
    },
    setCreateDeviceObservationModal(
      state,
      action: PayloadAction<ModalsState['createDeviceObservationModal']>,
    ) {
      state.createDeviceObservationModal = action.payload;
    },
    setUpdateDeviceObservationModal(
      state,
      action: PayloadAction<ModalsState['updateDeviceObservationModal']>,
    ) {
      state.updateDeviceObservationModal = action.payload;
    },
    setDeleteDeviceObservationModal(
      state,
      action: PayloadAction<ModalsState['deleteDeviceObservationModal']>,
    ) {
      state.deleteDeviceObservationModal = action.payload;
    },
    setUpdateDeviceDataConfigurationModal: (
      state,
      action: PayloadAction<ModalsState['updateDeviceDataConfigurationModal']>,
    ) => {
      state.updateDeviceDataConfigurationModal = action.payload;
    },
    setDeprecatedCreateMeasurementModal: (
      state,
      action: PayloadAction<ModalsState['deprecatedCreateMeasurementModal']>,
    ) => {
      state.deprecatedCreateMeasurementModal = action.payload;
    },
    setDeprecatedMeasurementsTableModal: (
      state,
      action: PayloadAction<ModalsState['deprecatedMeasurementsTableModal']>,
    ) => {
      state.deprecatedMeasurementsTableModal = action.payload;
    },
    setUpsertSmaConfigurationModal: (
      state,
      action: PayloadAction<ModalsState['upsertSmaConfigurationModal']>,
    ) => {
      state.upsertSmaConfigurationModal = action.payload;
    },
    setSmaSuccessTableModal: (
      state,
      action: PayloadAction<ModalsState['smaSuccessTableModal']>,
    ) => {
      state.smaSuccessTableModal = action.payload;
    },
    setUpsertAdvancedAlertsCreationModal: (
      state,
      action: PayloadAction<ModalsState['upsertAlertAdvancedCreationModal']>,
    ) => {
      state.upsertAlertAdvancedCreationModal = action.payload;
    },
    setSaveUserModal: (state, action: PayloadAction<ModalsState['saveUserModal']>) => {
      state.saveUserModal = action.payload;
    },
    setSimulateUserModal: (state, action: PayloadAction<ModalsState['simulateUserModal']>) => {
      state.simulateUserModal = action.payload;
    },
    setDeleteUserModal: (state, action: PayloadAction<ModalsState['deleteUserModal']>) => {
      state.deleteUserModal = action.payload;
    },
    setUserProfileModal: (state, action: PayloadAction<ModalsState['userProfileModal']>) => {
      state.userProfileModal = action.payload;
    },
    setCreateTemporaryUserModal: (
      state,
      action: PayloadAction<ModalsState['createTemporaryUserModal']>,
    ) => {
      state.createTemporaryUserModal = action.payload;
    },
    setExtendTemporaryUserModal: (
      state,
      action: PayloadAction<ModalsState['extendTemporaryUserModal']>,
    ) => {
      state.extendTemporaryUserModal = action.payload;
    },
    setExtendTemporaryUserTokenModal: (
      state,
      action: PayloadAction<ModalsState['extendTemporaryUserTokenModal']>,
    ) => {
      state.extendTemporaryUserTokenModal = action.payload;
    },
  },
});

export default reducer;
export const {
  setUpdateDeviceAlertsModal,
  setCreateDeviceObservationModal,
  setUpdateDeviceObservationModal,
  setDeleteDeviceObservationModal,
  setUpdateDeviceDataConfigurationModal,
  setDeprecatedMeasurementsTableModal,
  setDeprecatedCreateMeasurementModal,
  setUpsertSmaConfigurationModal,
  setSmaSuccessTableModal,
  setUpsertAdvancedAlertsCreationModal,
  setSaveUserModal,
  setSimulateUserModal,
  setDeleteUserModal,
  setUserProfileModal,
  setCreateTemporaryUserModal,
  setExtendTemporaryUserModal,
  setExtendTemporaryUserTokenModal,
} = actions;
