import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectProps } from '@mui/material/Select';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type Props<T extends FieldValues> = SelectProps & {
  control: Control<T>;
  name: Path<T>;
  options: {
    label: string;
    value: string;
  }[];
  rules?: any;
};

const FormSelectMulti = <T extends FieldValues>(props: Props<T>) => {
  const { name, label, control, options, rules, ...rest } = props;
  const labelId = name + '-label';

  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            {...rest}
            labelId={labelId}
            multiple
            value={value}
            onChange={onChange}
            input={<OutlinedInput label={label} />}
            renderValue={(selected: any) =>
              options
                .filter((o) => selected.includes(o.value))
                .map((o) => o.label)
                .join(', ')
            }
            MenuProps={MenuProps}
          >
            {options.map((o) => (
              <MenuItem key={o.value.toString()} id={o.value.toString()} value={o.value as any}>
                <Checkbox checked={value.includes(o.value)} />
                <ListItemText primary={o.label} />
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export { FormSelectMulti };
